import { Icon } from './Icon'
import { twJoin } from 'tailwind-merge'

interface Props {
    content: React.ReactNode
    className?: string
    color?: 'green' | 'pink' | 'yellow'
    icon?: boolean
    title?: string
}

/**
 * Component for displaying messages
 */
export function Message({
    content,
    className = '',
    color = 'green',
    icon = true,
    title = '',
}: Props) {
    const messageColor = {
        pink: 'bg-sonans-pink-hue border-2 border-sonans-new-pink',
        green: 'bg-sonans-green-hue border-2 border-sonans-new-green',
        yellow: 'bg-sonans-primary-hue border-2 border-sonans-primary',
    }

    const titleStyles = title ? 'flex-col' : 'flex-row'

    return (
        <div
            className={twJoin(
                messageColor[color],
                'p-2 sm:p-4 rounded-2xl flex gap-2',
                titleStyles,
                className
            )}
        >
            {title && <h3 className="text-lg">{title}</h3>}
            {icon && (
                <div className="w-auto">
                    <Icon type="info" />
                </div>
            )}
            <span className='text-sm md:base-base w-full'>{content}</span>
        </div>
    )
}
