import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { AppDispatch, AppState, Experience } from '@/types'

const currentYear = new Date().getFullYear()

// if todays date is > 15.august this year
const now = new Date();
const currentDay = now.getUTCDay();
const currentMonth = now.getUTCMonth();

const ageChecker: boolean = (currentMonth > 7 || (currentMonth === 8 && currentDay > 15))

export interface ExperiencesState {
    birthYear: string
    youngster: boolean
    ageClause: boolean
    experiences: Experience[]
}

export const initialState: ExperiencesState = {
    birthYear: '',
    youngster: false,
    ageClause: ageChecker,
    experiences: [],
}

export const experiencesSlice = createSlice({
    name: 'experiences',
    initialState,
    reducers: {
        setBirthYear(state, action: PayloadAction<string>) {
            state.birthYear = action.payload
            state.youngster = (currentYear - parseInt(action.payload) < 22)
        },
        addExperience(state, action: PayloadAction<Experience>) {
            state.experiences.push(action.payload)
        },
        removeExperience(state, action: PayloadAction<Experience>) {
            const currentExperience = state.experiences.findIndex(
                (experience) => experience === action.payload
            )

            state.experiences.splice(currentExperience, 1)
        },
        setExperiences(state, action: PayloadAction<Experience[]>) {
            state.experiences = action.payload
        },
    },
})

export const { setBirthYear, addExperience, removeExperience, setExperiences } =
    experiencesSlice.actions

export function handleExperiences(payload: Experience) {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const { experiences } = getState().experiences

        // if user selects "ingen av disse"
        if (payload === 'Ingen av disse') {
            // if "ingen av disse" already exists, remove it
            if (experiences.includes('Ingen av disse')) {
                dispatch(setExperiences([]))
                return
            }

            // if "ingen av disse" is selected remove other experiences
            dispatch(setExperiences(['Ingen av disse']))
            return
        } else {
            // if user selects other experiences remove "ingen av disse"
            dispatch(
                setExperiences(
                    experiences.filter(
                        (experience) => experience !== 'Ingen av disse'
                    )
                )
            )
        }

        if (experiences.includes(payload)) {
            // if user selects experience that already exists. remove it
            dispatch(removeExperience(payload))
        } else {
            // add experience to array
            dispatch(addExperience(payload))
        }
    }
}
