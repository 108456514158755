import { ICourse, ICourseGrade, IGrade, MinAvgGradeReqs, Reqs, RequirementCoursesItem } from '@/types'
import { CURRENT_YEAR } from './constants'
import { CourseCode } from '@/data/courseNameToCodeConversion'

/**
 * Helper function that calculates users age
 */
export function calcAge(birthYear: string): number {
    return CURRENT_YEAR - +birthYear
}

export function capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Helper function that sets many objects to localstorage
 */
export function setToLocalStorage(...items: string[]): void {
    items.forEach((item) => {
        localStorage.setItem(item[0], JSON.stringify(item[1]))
    })
}

/**
 * helper functuin to check if course name includes "studieforberedende"
 */
function includesStudyPreperation(grades: ICourseGrade[]) {
    return grades
        .map((grade) => grade.course.name)
        .join(' ')
        .includes('studieforberedende')
}

/**
 * Check if user has Naturfag studieforberedende which covers naturfag
 */
export function hasNatrualScience(course: ICourse, grades: ICourseGrade[]) {
    if (course.code.includes('NAT') && includesStudyPreperation(grades)) {
        return !course.name.includes('studieforberedende')
    } else {
        return false
    }
}

/**
 * Helper function that joins classes to a single string
 */
export function classes(...classes: string[]) {
    return classes.filter((item) => item).join(' ')
}

/**
 * Helper function that converts course names into course codes in a study program's requirement courses object
 */
export const convertReqsToCodes = (reqs: Reqs | null): Reqs | null => {
    if (!reqs) {
        return null
    }

    const convertedReqs: Reqs = {}

    if (reqs.either) {
        convertedReqs.either = reqs.either.map((eitherArray) => {
            return eitherArray.map((courseName) => CourseCode(courseName))
        })
    }

    if (reqs.all) {
        convertedReqs.all = reqs.all.map((allArray) => {
            return allArray.map((courseName) => CourseCode(courseName))
        })
    }

    return convertedReqs
}

/**
 * Helper function that converts course names into course codes in a study program's required average grades object
 */
export const convertMinAvgGradeReqsToCodes = (
    minAvgGradeReqs: MinAvgGradeReqs[] | null
): MinAvgGradeReqs[] | null => {
    if (!minAvgGradeReqs) {
        return null
    }

    return minAvgGradeReqs.map((minAvgGradeReq) => {
        return {
            name: Array.isArray(minAvgGradeReq.name)
                ? minAvgGradeReq.name.map((courseName) =>
                      CourseCode(courseName)
                  )
                : CourseCode(minAvgGradeReq.name),
            grade: minAvgGradeReq.grade,
            replacements_with_no_grade_req:
                minAvgGradeReq.replacements_with_no_grade_req,
        }
    })
}

/**
 * Helper function that converts course names into course codes in a study program's entire requirement object (reflecting JSON requirement structure in requirementCourses.json)
 * For further comparison with user's courses and grades from AppState
 */
export const convertCoursesToCodes = (reqCourses: RequirementCoursesItem) : RequirementCoursesItem => {
    
    const convertedReqCourses: RequirementCoursesItem = {
        name: reqCourses.name,
        prereqs: convertReqsToCodes(reqCourses.prereqs),
        reqs: convertReqsToCodes(reqCourses.reqs),
        min_avg_grade_reqs: convertMinAvgGradeReqsToCodes(
            reqCourses.min_avg_grade_reqs
        ),
        other: reqCourses.other,
    }

    return convertedReqCourses
}

