import * as Tooltip from '@radix-ui/react-tooltip'
import * as Popover from '@radix-ui/react-popover'
import { twJoin } from 'tailwind-merge'
import { AppDispatch, AppState, ICourse, IGrade, TExamType } from '@/types'
import { Popup, Text } from '../UI'
import { CourseInfo } from './CourseInfo'
import { GradeItem } from './GradeItem'
import React, { useEffect, useRef, useState } from 'react'
import { Add, Close } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { handleGrades } from '@/reducers/grades'
import { ExtraGradeRow } from './ExtraGradeRow'

interface Props {
    course: ICourse
    disabled: boolean
    onRemoveCourse: (course: ICourse) => void
}

export const GradeRow: React.FC<Props> = ({
    course,
    disabled,
    onRemoveCourse,
}) => {
    const [selectedGrade, setSelectedGrade] = useState<IGrade>({
        grade: 0,
        type: 'grade',
        examType: '',
    })
    const isFirstRender = useRef(true)

    const [exams, setExams] = useState<IGrade[]>([])

    const gradeState = useSelector((state: AppState) => state.grades.grades)

    // Find the grade for the course on initial render. The user can have grades in localStorage or from the database via reference code
    useEffect(() => {
        if (isFirstRender.current) {
            // This runs only on the first render
            const foundCourse = gradeState.find(
                (grade) => grade.course.id === course.id
            )

            if (foundCourse) {
                const foundGrade = foundCourse?.grades?.find(
                    (grade) => grade.type === 'grade' && grade.examType === ''
                )

                const foundExams = foundCourse?.grades?.filter(
                    (grade) => grade.type === 'exam'
                )

                if (foundGrade) {
                    setSelectedGrade(foundGrade)
                }

                if (foundExams) {
                    setExams(foundExams)
                }
            }

            isFirstRender.current = false
        }
    }, [gradeState, course.id])

    const dispatch = useDispatch<AppDispatch>()

    const grades = Array.from({ length: 6 }, (_, index) => index + 1)

    const examOptions: TExamType[] = [
        'Skriftlig eksamen',
        'Muntlig eksamen',
        'Muntlig-praktisk eksamen',
    ]

    const filteredExamOptions = examOptions.filter(
        (option) => !exams.some((exam) => exam.examType === option)
    )

    return (
        <>
            <div className="flex justify-between flex-wrap md:flex-nowrap items-center">
                <div
                    className={twJoin(
                        'w-full md:w-1/2',
                        disabled ? 'opacity-30' : ''
                    )}
                >
                    <Text className="mb-2 md:mb-0">
                        {course.name}
                        {course.replacedCourses.length > 0 && (
                            <span className="ml-1 flex items-center">
                                <Popup
                                    content={<CourseInfo course={course} />}
                                />
                            </span>
                        )}
                    </Text>
                </div>
                <div className="flex justify-between w-full items-center">
                    <div className="flex flex-col w-[160px] xs:w-[200px] sm:w-[250px]">
                        <section className="grid grid-cols-6 bg-white w-full rounded-full border border-black z-20 ">
                            {grades.map((grade) => (
                                <GradeItem
                                    grade={grade}
                                    key={grade}
                                    selectedGrade={selectedGrade.grade}
                                    type="grade"
                                    onClick={(grade) => {
                                        if (disabled) return

                                        // if the selected grade is the same as the grade that was clicked, we remove the grade
                                        if (selectedGrade.grade === grade) {
                                            setSelectedGrade({
                                                grade: 0,
                                                type: 'grade',
                                                examType: '',
                                            })
                                            dispatch(
                                                handleGrades({
                                                    grade: {
                                                        grade: 0,
                                                        type: 'grade',
                                                        examType: '',
                                                    },
                                                    course: course,
                                                })
                                            )
                                            return
                                        }

                                        const newGrade: IGrade = {
                                            grade: grade,
                                            type: 'grade',
                                            examType: '',
                                        }

                                        setSelectedGrade(newGrade)
                                        dispatch(
                                            handleGrades({
                                                grade: newGrade,
                                                course: course,
                                            })
                                        )
                                    }}
                                    className=""
                                />
                            ))}
                        </section>
                    </div>

                    {/** A user can have no more than 3 exams */}
                    {exams.length < 3 && (
                        <Popover.Root>
                            <Popover.Trigger>
                                <Tooltip.Provider>
                                    <Tooltip.Root>
                                        <Tooltip.Trigger>
                                            <div
                                                className={`border border-black rounded-full aspect-square shadow-hard-sm flex justify-center items-center w-4 xs:w-7 sm:w-9 mx-1 mt-1 xs:mt-[2px] transition-transform duration-150 ease-in-out transform bg-sonans-new-green`}
                                            >
                                                <Add fontSize="inherit" />
                                            </div>
                                        </Tooltip.Trigger>
                                        <Tooltip.Portal>
                                            <Tooltip.Content className="bg-white p-2 shadow-md rounded-lg mx-auto w-1/2 text-center text-xs">
                                                Legg til flere karakterer i
                                                dette faget
                                                <Tooltip.Arrow className="fill-white" />
                                            </Tooltip.Content>
                                        </Tooltip.Portal>
                                    </Tooltip.Root>
                                </Tooltip.Provider>
                                <Popover.Portal>
                                    <Popover.Content className="bg-white shadow-md rounded-lg mx-auto text-left text-xs flex flex-col overflow-hidden">
                                        {filteredExamOptions.map((option) => (
                                            <button
                                                onClick={() => {
                                                    if (disabled) return
                                                    // append a new item to the exams array to render new rows
                                                    setExams([
                                                        ...exams,
                                                        {
                                                            grade: 0,
                                                            examType: option,
                                                            type: 'exam',
                                                        },
                                                    ])
                                                }}
                                                className="text-left p-4 border-b border-neutral-100 hover:bg-sonans-new-green"
                                                key={option}
                                            >
                                                {option}
                                            </button>
                                        ))}
                                        <Popover.Arrow className="fill-white" />
                                    </Popover.Content>
                                </Popover.Portal>
                            </Popover.Trigger>
                        </Popover.Root>
                    )}

                    <button
                        className="rounded-full aspect-square flex justify-center items-center"
                        onClick={() => {
                            dispatch(
                                handleGrades({
                                    grade: {
                                        grade: 0,
                                        type: 'grade',
                                        examType: '',
                                    },
                                    course: course,
                                })
                            )
                            onRemoveCourse(course)
                        }}
                    >
                        <Close fontSize="inherit" />
                    </button>
                </div>
            </div>
            {exams.length > 0 &&
                exams.map((exam) => (
                    <ExtraGradeRow
                        course={course}
                        key={exam.examType}
                        selectedExamType={exam.examType}
                        disabled={disabled}
                        onRemoveExam={(examType) => {
                            setExams(
                                exams.filter(
                                    (exam) => exam.examType !== examType
                                )
                            )
                        }}
                    />
                ))}
        </>
    )
}
