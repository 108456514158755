import { Label } from './Label'
import { twJoin } from 'tailwind-merge'
import React from 'react'
import { Popup } from './Popup'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: React.ReactNode
    noIcon?: boolean
    popupTitle?: string | React.ReactNode
    popupContent?: string | React.ReactNode
}

export function Input({
    label,
    noIcon,
    popupTitle = '',
    popupContent,
    ...props
}: Props) {
    return (
        <div
            className={twJoin(
                `flex flex-col gap-1 relative`,
                props.className || ''
            )}
        >
            {(label || popupContent) && (
                <div className="flex gap-2">
                    {label && <Label htmlFor={props.id}>{label}</Label>}
                    {popupContent && (
                        <Popup title={popupTitle} content={popupContent} />
                    )}
                </div>
            )}
            <div className="relative flex items-center ">
                <input
                    {...props}
                    className={twJoin(
                        `text-[0.625rem] xs:text-[0.75rem] sm:text-[1rem] py-1 px-2 xs:px-4 xs:py-2 rounded-full border border-sonans-new-gray outline-none placeholder:text-sonans-new-gray flex-1`,
                        props.className || ''
                    )}
                />
                {!noIcon && (
                    <span className="material-icons absolute right-0 mr-4 pointer-events-none text-sonans-new-gray">
                        search
                    </span>
                )}
            </div>
        </div>
    )
}
