import { useSelector } from 'react-redux'
import { AppState } from '@/types'
import { toFixedDecimal } from '@/reducers/helpers'
import { Text } from '../UI/Text'
import {
    helperTextORDF,
    helperTextORD,
    helperTextTwentythreeFileRule,
} from '@/helpers/HelperTexts'

type Mode =
    | 'regular'
    | 'twentythreeFiveRule'
    | 'twentythreeFiveRuleExcludeScience'

interface Props {
    mode: Mode
}

export function ResultsCard({ mode }: Props) {
    const gradePoints = useSelector(
        (state: AppState) => state.results.gradePoints
    )
    const sciencePoints = useSelector(
        (state: AppState) => state.results.sciencePoints
    )
    const additionalPoints = useSelector(
        (state: AppState) => state.results.additionalPoints
    )
    const agePoints = useSelector((state: AppState) => state.results.agePoints)
    const youngster = useSelector(
        (state: AppState) => state.experiences.youngster
    )

    function points(mode: Mode) {
        return [
            {
                Karakterpoeng: gradePoints[mode],
            },
            {
                'Realfags- og språkpoeng':
                    mode !== 'twentythreeFiveRuleExcludeScience'
                        ? sciencePoints
                        : 0,
            },
            {
                Tilleggspoeng: additionalPoints,
            },
            { Alderspoeng: agePoints[mode] },
        ]
    }

    function title(mode: Mode): string {
        if (mode === 'twentythreeFiveRule') return 'Ditt snitt med 23/5 regelen'
        if (mode === 'twentythreeFiveRuleExcludeScience')
            return 'Ditt snitt med 23/5 regelen uten realfag'
        return 'Ditt snitt'
    }

    let total = 0
    let youngTotal = 0

    let competitionPoints = useSelector(
        (state: AppState) => state.results.competitionPoints
    )


    function renderIfStudenHasQuotaAdvantages() {
        return (
            <div>
                {youngster && (
                    <div
                        className="border-b grid"
                        key="totscore"
                    >
                        {points(mode).map((point, index) => {
                            Object.entries(point).map((item, index) => {
                                if (
                                    item[0] !== 'Tilleggspoeng' &&
                                    item[0] !== 'Alderspoeng'
                                ) {
                                    youngTotal += item[1]
                                }
                            })
                            if (index === 0 || index === 1) {
                                return (
                                    <div key={index}>
                                        {Object.entries(point).map(
                                            (item, index) => (
                                                <div
                                                    className="border-b grid grid-cols-[4fr,1fr] lg:grid-cols-[5fr,1fr] py-1"
                                                    key={index}
                                                >
                                                    <Text className="flex">
                                                        {item[0]}{' '}
                                                    </Text>
                                                    <Text>
                                                        {toFixedDecimal(
                                                            item[1] as number
                                                        )}
                                                    </Text>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )
                            }
                        })}

                        <div
                            className="border-b grid grid-cols-[4fr,1fr] lg:grid-cols-[5fr,1fr] bg-sonans-new-pink py-1"
                            key="totscore"
                        >
                            <Text className="flex">
                                <b>
                                    Dine skolepoeng (kvote for
                                    førstegangsvitnemål)
                                </b>
                            </Text>
                            <Text
                                popupTitle={helperTextORDF.title}
                                popupContent={helperTextORDF.content}
                            >
                                {agePoints &&
                                    toFixedDecimal(
                                        youngTotal // - agePoints.regular
                                    )}
                            </Text>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className={'py-3'}>
            <Text
                type="subtitle"
                className="pb-4"
                popupTitle={
                    mode === 'twentythreeFiveRule' ||
                    mode === 'twentythreeFiveRuleExcludeScience'
                        ? helperTextTwentythreeFileRule.title
                        : ''
                }
                popupContent={
                    mode === 'twentythreeFiveRule' ||
                    mode === 'twentythreeFiveRuleExcludeScience'
                        ? helperTextTwentythreeFileRule.content
                        : ''
                }
            >
                {youngster ? 'Ditt snitt' : title(mode)}
            </Text>
            <span>{renderIfStudenHasQuotaAdvantages()}</span>
            <div>
                {points(mode).map((point, index) => {
                    let skip = false
                    Object.keys(point).map((e) => {
                        if (
                            youngster &&
                            (e.includes('Karakter') || e.includes('Realfag'))
                        ) {
                            skip = true
                        }
                    })

                    Object.entries(point).map(
                        (item, index) => (total += item[1])
                    )
                    if (!skip) {
                        return (
                            <div key={index}>
                                {Object.entries(point).map((item, index) => {
                                    return (
                                        <div
                                            className="border-b grid grid-cols-[4fr,1fr] lg:grid-cols-[5fr,1fr] py-1"
                                            key={index}
                                        >
                                            <Text className="flex">
                                                {item[0]}{' '}
                                            </Text>
                                            <Text className="">
                                                {toFixedDecimal(
                                                    item[1] as number
                                                )}
                                            </Text>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                })}

                <div
                    className="border-b grid grid-cols-[4fr,1fr] lg:grid-cols-[5fr,1fr] bg-sonans-new-green py-1"
                    key="totscore"
                >
                    <Text className="flex">
                        <b>Dine konkurransepoeng (ordinær kvote)</b>
                    </Text>

                    {mode === 'regular' && (
                        <Text
                            popupTitle={helperTextORD.title}
                            popupContent={helperTextORD.content}
                        >
                            {toFixedDecimal(total)}
                        </Text>
                    )}
                    {competitionPoints.twentythreeFiveRule >
                        competitionPoints.regular &&
                        (mode === 'twentythreeFiveRule' ||
                            mode === 'twentythreeFiveRuleExcludeScience') && (
                            <Text
                                popupTitle={helperTextORD.title}
                                popupContent={helperTextORD.content}
                            >
                                <span
                                    style={{ color: 'green' }}
                                    className={'font-bold'}
                                >
                                    {toFixedDecimal(total)}
                                </span>
                            </Text>
                        )}

                    {competitionPoints.twentythreeFiveRule ===
                        competitionPoints.regular &&
                        mode === 'twentythreeFiveRule' && (
                            <Text
                                popupTitle={helperTextORD.title}
                                popupContent={helperTextORD.content}
                            >
                                {toFixedDecimal(total)}
                            </Text>
                        )}
                </div>
            </div>
        </div>
    )
}
