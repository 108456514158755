import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import update from 'immutability-helper'
import { ICourse, AppState, AppDispatch } from '@/types'
import { API } from '@/utils/config'
import { generateCourseParams } from './helpers'

interface CourseState {
    defaultCourses: ICourse[]
    additionalCourses: ICourse[]
    isLoading: boolean
    error: string
}

const initialState: CourseState = {
    defaultCourses: [],
    additionalCourses: [],
    isLoading: false,
    error: '',
}

export const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        setDefaultCourses(state, action: PayloadAction<ICourse[]>) {
            state.defaultCourses = action.payload
        },
        setAdditionalCourses(state, action: PayloadAction<ICourse[]>) {
            state.additionalCourses = action.payload
        },
        addCourse(state, action: PayloadAction<ICourse>) {
            const currentIndex = state.additionalCourses.findIndex(
                (course) => course.id === action.payload.id
            )

            state.additionalCourses.splice(currentIndex, 1)
            state.defaultCourses.push(action.payload)
        },
        removeCourse(state, action: PayloadAction<ICourse>) {
            const currentIndex = state.defaultCourses.findIndex(
                (course) => course.id === action.payload.id
            )

            state.defaultCourses.splice(currentIndex, 1)
            state.additionalCourses.push(action.payload)
        },
        reorderDefaultCourses(state, action: PayloadAction<ICourse[]>) {
            state.defaultCourses = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
    },
})

export const {
    removeCourse,
    addCourse,
    setDefaultCourses,
    setAdditionalCourses,
    reorderDefaultCourses,
    setIsLoading,
    setError,
} = coursesSlice.actions

export function fetchCourses() {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        try {
            dispatch(setError(''))
            dispatch(setIsLoading(true))
            const { selectedEducationalProgram } =
                getState().educationalPrograms
            const { selectedProgramType } = getState().programTypes

            // if selectedProgramType is 23/5 get twentythreeFive courses
            if (selectedProgramType?.id === 3) {
                const { data, status } = await axios.get(
                    API(`courses/get_twentythree_five_rule`)
                )
                if (status !== 200)
                    throw new Error(
                        'Det oppsto en feil med å hente fag. Prøv igjen'
                    )
                dispatch(setDefaultCourses(data))
            }

            if (!selectedEducationalProgram) return

            let params = generateCourseParams(selectedEducationalProgram)

            const { data } = await axios.get(
                API(`courses/get_courses_by_program?${params}`)
            )
            dispatch(setDefaultCourses(data))
        } catch (error: any) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setIsLoading(false))
        }
    }
}

export function fetchAdditionalCourses() {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        try {
            dispatch(setError(''))
            dispatch(setIsLoading(true))
            const { selectedProgramType } = getState().programTypes

            // if selected program type is 23/5 fetch only science and language courses as additional courses
            if (selectedProgramType?.id === 3) {
                const { data, status } = await axios.get(
                    API(`courses/get_science_courses`)
                )
                if (status !== 200)
                    throw new Error(
                        'Det oppsto en feil med å hente fag. Prøv igjen'
                    )
                dispatch(setAdditionalCourses(data))
            } else {
                const { data: dataOptionalCourses } = await axios.get(
                    API(`courses/get_optional_program_courses`)
                )
                dispatch(setAdditionalCourses([...dataOptionalCourses]))
            }
        } catch (error: any) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setIsLoading(false))
        }
    }
}

export function sortCourses(dragIndex: number, hoverIndex: number) {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const { defaultCourses } = getState().courses

        const newState = update(defaultCourses, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, defaultCourses[dragIndex]],
            ],
        })

        dispatch(reorderDefaultCourses(newState))
    }
}
