import React from 'react'
import { useDnD } from '@/hooks/useDnD'
import { GradeRow } from './GradeRow'
import { ICourse } from '@/types'

type Props = {
    course: ICourse
    onRemoveCourse: () => void
    disabled: boolean
    index: number
    moveGrade: (dragIndex: number, hoverIndex: number) => void
    id: number
}

/**
 * Component that displays a single course with grades and exam grades. This component is rendered in the CourseList component.
 */
export const CourseItem: React.FC<Props> = ({
                                                course,
                                                onRemoveCourse,
                                                disabled,
                                                index,
                                                moveGrade,
                                                id,
                                            }: Props) => {

    const { ref, handlerId, isDragging } = useDnD(moveGrade, index, id)

    return (
        <li
            data-testid={'course-item-' + course.code}
            style={{ opacity: isDragging ? 0 : 1 }}
            ref={ref}
            data-handler-id={handlerId}
            className="flex flex-col justify-center border-b border-sonans-new-gray py-8"
        >
            <GradeRow course={course} disabled={disabled} onRemoveCourse={onRemoveCourse} />
        </li>
    )
}
