import { configureStore } from '@reduxjs/toolkit'
import { coursesSlice } from './courses'
import { programTypesSlice } from './programTypes'
import { experiencesSlice } from './experiences'
import { educationalProgramsSlice } from './educationalPrograms'
import { gradesSlice } from './grades'
import { resultsSlice } from './results'
import { favoriteStudyFieldsSlice } from './favoriteStudyFields'
import { referenceCodeSlice } from './referenceCode'
import { actionFeedbackSlice } from './actionFeedback'
import { api } from '@/api/api'

export const store = configureStore({
    reducer: {
        [gradesSlice.name]: gradesSlice.reducer,
        [resultsSlice.name]: resultsSlice.reducer,
        [coursesSlice.name]: coursesSlice.reducer,
        [educationalProgramsSlice.name]: educationalProgramsSlice.reducer,
        [programTypesSlice.name]: programTypesSlice.reducer,
        [experiencesSlice.name]: experiencesSlice.reducer,
        [favoriteStudyFieldsSlice.name]: favoriteStudyFieldsSlice.reducer,
        [referenceCodeSlice.name]: referenceCodeSlice.reducer,
        [actionFeedbackSlice.name]: actionFeedbackSlice.reducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
})
