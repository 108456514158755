import { AppLayout } from '@/components/UI/AppLayout'
import { Button, Container } from '@/components/UI'
import { Text } from '@/components/UI/Text'
import { BookCounseling } from '@/components/UI/BookCounseling'
import HigherEducationPathList from '@/components/UI/HigherEducationPath/HigherEducationPathList'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const Favorites = () => {
    const favoriteStudies = useSelector(
        (state: any) => state.favoriteStudyFields.favoriteObjects
    )


    return (
        <AppLayout>
            <Container size="lg" className="animate-reveal">
                <div className="font-light">
                    <div className="mb-3 py-4">
                        <Text className="text-4xl" type="title">
                            Dine favoritter
                        </Text>
                    </div>
                </div>
            </Container>
            <div className="animate-reveal md:max-w-lg lg:max-w-xl mx-auto">
                {favoriteStudies.length > 0 && <HigherEducationPathList studyFields={favoriteStudies} />}
            </div>
            <div>
                <Container size="lg" className="py-4">
                    <Button size="sm" className="text-xs">
                        <Link to="/studiemuligheter">
                            Tilbake til studiemuligheter
                        </Link>
                    </Button>
                </Container>
            </div>

            <BookCounseling textType={'study'} />
        </AppLayout>
    )
}
