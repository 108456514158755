import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '@/types'

type BackButtonProps = {
    className?: string
    buttonStyle?: string
}

export const BackButton: React.FC<BackButtonProps> = ({
    className = '',
    buttonStyle = 'plain',
}) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const knownPaths = [
        'start',
        'utdanningsprogram',
        'karakterer',
        'studiemuligheter',
    ]
    const [prevPath, setPrevPath] = React.useState('start')
    const twentythreeFiveRule = useSelector(
        (state: AppState) => state.grades.twentythreeFiveRule
    )

    useEffect(() => {
        knownPaths.forEach((k, index) => {
            if (pathname.replace('/', '') === k && prevPath !== 'start') {
                setPrevPath('start')
            }

            if (pathname.replace('/', '') !== 'start') {
                if (pathname.replace('/', '') === k) {
                    if (prevPath !== knownPaths[index - 1]) {
                        if (k === 'karakterer' && twentythreeFiveRule) {
                            setPrevPath('start')
                        } else {
                            setPrevPath(knownPaths[index - 1])
                        }
                    }
                }
            }
        })
    }, [])

    return (
        <>
            {pathname.replace('/', '') !== 'start' && (
                <button
                    onClick={() => {
                        if (pathname === '/studiemuligheter/favoritter') {
                            navigate('/studiemuligheter')
                            return
                        }
                        navigate('/' + prevPath)
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-8 h-8"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                        />
                    </svg>
                </button>
            )}
        </>
    )
}
