import { ICourse } from '@/types'

interface Props {
    course: ICourse
}

export function CourseInfo({ course }: Props) {
    return (
        <ul className="flex flex-col gap-4">
            {course.replacedCourses.length > 0 && (
                <ul>
                    <strong>Dette faget erstatter:</strong>
                    {course.replacedCourses.map((replacedCourse) => (
                        <li key={replacedCourse.id}>{replacedCourse.name}</li>
                    ))}
                </ul>
            )}
        </ul>
    )
}
