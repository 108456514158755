// @ts-nocheck

import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const itemTypes = {
    GRADE: 'grade',
}

export function useDnD(
    callback: (dragIndex: number, hoverIndex: number) => void,
    index: number,
    id: number
) {
    const ref = useRef<HTMLLIElement>(null)

    const [{ handlerId }, drop] = useDrop({
        accept: itemTypes.GRADE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }

            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) return

            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            const clientOffset = monitor.getClientOffset()

            const hoverClientY = clientOffset.y - hoverBoundingRect.top

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

            callback(dragIndex, hoverIndex)

            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: itemTypes.GRADE,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(drop(ref))

    return {
        isDragging,
        handlerId,
        ref,
    }
}
