import { useLocation } from 'react-router-dom'
import { Container } from './Container'
import { Logo } from './Logo'
import NavBar from './NavBar'
import { BackButton } from '@/components/UI/BackButton'
import { FavoriteBorder } from '@mui/icons-material'
import { Link } from 'react-router-dom'

export function Header() {
    const { pathname } = useLocation()

    const EXCLUDES_PATHS = ['/start', '/utdanningsprogram']

    return (
        <header className="shadow-[0px_-8px_10px] sticky top-0 z-50 bg-white h-auto">
            <div className="bg-sonans-primary h-[3rem] 2xl:h-[4rem]">
                <Container className="flex justify-between items-center h-full">
                    <div className="w-8 h-8">
                        <BackButton />
                    </div>
                    <a href="https://sonans.no/">
                        <Logo type="logo" className="w-24 2xl:w-32" />
                    </a>
                    <div className="w-8 h-8">
                        {(pathname === '/studiemuligheter' ||
                            pathname === '/studiemuligheter/favoritter') && (
                            <Link to="/studiemuligheter/favoritter">
                                <FavoriteBorder fontSize="large" />
                            </Link>
                        )}
                    </div>
                </Container>
            </div>
            {!EXCLUDES_PATHS.includes(pathname) && (
                <Container className="flex justify-center h-14 sm:h-20 2xl:h-16 items-center">
                    <NavBar />
                </Container>
            )}
        </header>
    )
}
