import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StudyItem } from '@/types'

interface FavoriteStudyFieldsState {
    favoriteObjects: StudyItem[]
}

const initialState: FavoriteStudyFieldsState = {
    favoriteObjects: [],
}

export const favoriteStudyFieldsSlice = createSlice({
    name: 'favoriteStudyFields',
    initialState,
    reducers: {
        setFavoriteObjects(state, action: PayloadAction<StudyItem>) {
            
            if (
                state.favoriteObjects
                    ?.map((object) => object.id)
                    .includes(action.payload.id)
            ) {
                state.favoriteObjects = state.favoriteObjects.filter(
                    (object) => object.id !== action.payload.id
                )
            } else {
                state.favoriteObjects.push(action.payload)
            }
        },
    },
})

export const { setFavoriteObjects } = favoriteStudyFieldsSlice.actions

export default favoriteStudyFieldsSlice.reducer
