import { StudyQuotas } from '@/types'
import { ChevronRight } from '@mui/icons-material'
import { Fragment } from 'react/jsx-runtime'
import { twJoin } from 'tailwind-merge'

interface Props {
    isNew: boolean
    quotas: StudyQuotas[]
    quotaYears: string[]
    quotasExpanded: boolean
    onQuotasExpandedChange: (expanded: boolean) => void
}

export function HigherEducationPointsTable({
    isNew,
    quotas,
    quotaYears,
    quotasExpanded,
    onQuotasExpandedChange,
}: Props) {
    if (isNew) return <div></div>

    return (
        <div className="border md:border-0 border-black rounded-lg py-2 md:p-0 text-xs md:text-sm">
            <div className="grid grid-cols-2 font-bold">
                <p className="pl-2">Tidligere poenggrenser</p>
                <div className="grid grid-cols-4 gap-4 content-center justify-center">
                    {quotaYears &&
                        quotaYears.map((year) => (
                            <p className="text-center">{year}</p>
                        ))}
                </div>
            </div>
            <div className="grid grid-cols-2">
                {quotas &&
                    quotas
                        .slice(0, quotasExpanded ? quotas.length : 2)
                        .map((q, index: number) => (
                            <Fragment key={q.quota_type}>
                                <p
                                    className={twJoin(
                                        'border-b py-2 px-2',
                                        index % 2 === 0
                                            ? 'bg-sonans-new-green'
                                            : 'bg-sonans-new-pink'
                                    )}
                                >
                                    <span>
                                        {q.quota_name ===
                                        'Ordinær primærvitnemålskvote'
                                            ? 'Førstegangsvitnemål'
                                            : q.quota_name}
                                    </span>{' '}
                                    <span className="font-bold">
                                        ({q.quota_type})
                                    </span>
                                </p>
                                <div
                                    className={twJoin(
                                        'grid grid-cols-4 content-center justify-center gap-4 border-b py-2 text-center',
                                        index % 2 === 0
                                            ? 'bg-sonans-new-green'
                                            : 'bg-sonans-new-pink'
                                    )}
                                >
                                    {q.quotas.map((a, index: number) => (
                                        <p
                                            className="items-center text-center w-full"
                                            key={index}
                                        >
                                            {a.point_limit === 'ALL'
                                                ? 'ALLE'
                                                : a.point_limit}
                                        </p>
                                    ))}
                                </div>
                            </Fragment>
                        ))}
            </div>
            {quotas.length > 2 && (
                <button
                    className="flex items-center justify-center w-full py-2"
                    onClick={() => onQuotasExpandedChange(!quotasExpanded)}
                >
                    Se alle kvoter{' '}
                    <ChevronRight
                        className={
                            quotasExpanded
                                ? 'rotate-[-90deg]'
                                : 'rotate-[90deg]'
                        }
                    />
                </button>
            )}
        </div>
    )
}
