import React, { useEffect, useState } from 'react'
import { AppDispatch, AppState, StudyItem } from '@/types'
import {
    ArrowForwardIosOutlined,
    Favorite,
    FavoriteBorderOutlined,
} from '@mui/icons-material'
import { twJoin } from 'tailwind-merge'
import { HigherEducationPathDetails } from './HigherEducationPathDetails'
import { useDispatch, useSelector } from 'react-redux'
import { setFavoriteObjects } from '@/reducers/favoriteStudyFields'

export type HigherEducationPathItemProps = {
    studyItem: StudyItem
}

const HigherEducationPathItem: React.FC<HigherEducationPathItemProps> = ({
    studyItem,
}) => {
    const [accordionOpen, setAccordionOpen] = useState(false)
    const [quotasExpanded, setQuotasExpanded] = useState(false)

    const [isFavorite, setIsFavorite] = useState<boolean>()

    const favoriteStudies = useSelector(
        (state: AppState) => state.favoriteStudyFields.favoriteObjects
    )

    const dispatch = useDispatch<AppDispatch>()


    const toggleFavorite = () => {
        setIsFavorite(!isFavorite)
        dispatch(setFavoriteObjects(studyItem))
    }

    useEffect(() => {
        const isFavorite = favoriteStudies.find(
            (study) => study.id === studyItem.id
        )

        if (isFavorite) {
            setIsFavorite(true)
        }
    }, [favoriteStudies, studyItem.id])

    return (
        <div className="p-3 md:p-4 border-b border-black last:border-b-0">
            <div
                className={twJoin(
                    accordionOpen
                        ? 'grid-cols-[24px,10fr,1fr,1fr,24px]'
                        : 'grid-cols-[24px,3fr,1fr,1fr,24px]',
                    'grid gap-2 md:grid-cols-[0.5fr,3fr,1fr,1fr,1fr,1fr] w-full text-sm hover:cursor-pointer'
                )}
                onClick={(e) => {
                    const target = e.target as HTMLElement
                    if (target.closest('button')?.id === 'favorite-button') {
                        return
                    }

                    setAccordionOpen(!accordionOpen)
                }}
            >
                <button
                    id="favorite-button"
                    className="mr-auto"
                    onClick={toggleFavorite}
                >
                    {isFavorite ? (
                        <Favorite className="animate-pop" />
                    ) : (
                        <FavoriteBorderOutlined />
                    )}
                </button>

                <p
                    className={twJoin(
                        accordionOpen ? '' : 'truncate',
                        'md:truncate'
                    )}
                >
                    {studyItem.name} - {studyItem.institution.short_name}{' '}
                    <span className="block md:hidden">
                        {studyItem.teaching_location &&
                            studyItem.teaching_location.name}{' '}
                    </span>
                </p>

                <p className="truncate hidden md:flex">
                    {studyItem && studyItem.teaching_location.name}{' '}
                </p>

                {!accordionOpen ? (
                    <p>
                        <span className="bg-sonans-new-green">
                            {studyItem.latest_ord_quota}
                        </span>
                    </p>
                ) : (
                    <div></div>
                )}

                {!accordionOpen ? (
                    <p>
                        <span className="bg-sonans-new-pink">
                            {studyItem.latest_ordf_quota}
                        </span>
                    </p>
                ) : (
                    <div></div>
                )}

                <button>
                    <ArrowForwardIosOutlined
                        className={`transform origin-center ml-auto transition-all duration-100 ease-out ${
                            accordionOpen ? 'rotate-[-90deg]' : 'rotate-90'
                        }`}
                    />
                </button>
            </div>

            {studyItem.has_early_deadline && (
                <p className="text-blue-600 text-xs ml-[32px] md:ml-[76px]">
                    Har tidlig søknadsfrist
                </p>
            )}

            {accordionOpen && (
                <HigherEducationPathDetails
                    studyItem={studyItem}
                    accordionOpen={accordionOpen}
                    quotasExpanded={quotasExpanded}
                    setQuotasExpanded={setQuotasExpanded}
                />
            )}
        </div>
    )
}

// @ts-ignore
export default HigherEducationPathItem
