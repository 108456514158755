import React, {useEffect} from 'react'
import {Message} from '@/components/UI/Message'
import {Container} from './Container'
import {Text} from '@/components/UI'

type BookCounselingProps = {
    className?: string
    textType?: string
}

export const BookCounseling: React.FC<BookCounselingProps> = ({
                                                                  className = '',
                                                                  textType = '',
                                                              }) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src =
            'https://public-sonans-bucket.s3.eu-west-1.amazonaws.com/sonans-serveify-a2.js'
        document.body.appendChild(script)
    }, [])

    const calcText = ['Rådgivning er alltid gratis. Snakk med oss i dag dersom du har spørsmål om utregningen, eller noe helt annet!']
    const studyText = ['Rådgivning er alltid gratis. Snakk med oss i dag dersom du har spørsmål om snitt, studiemuligheter, eller noe helt annet!']

    return (
        <div className="bg-sonans-new-green p-4">
            <Container className="bg-white w-1/2 mx-auto rounded-xl p-4">
                <div className={'py-3 px-5'}>
                    <Message
                        color="green"
                        content={
                            <Text>
                                {textType === 'calc' &&

                                    calcText[0]


                                }
                                {textType === 'study' &&
                                    studyText[0]

                                }
                            </Text>
                        }
                    />
                </div>
                <div id="root" className={className}></div>
            </Container>
        </div>
    )
}
