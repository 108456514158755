import { InputHTMLAttributes } from 'react'
import { twJoin } from 'tailwind-merge'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    backgroundColor?: 'primary' | 'pink' | 'green' | 'black'
    fullWidth?: boolean
    label: string
}

export function Checkbox({
    backgroundColor = 'primary',
    fullWidth = false,
    label,
    ...props
}: Props) {
    const backgroundColorClasses = {
        primary: ' border-sonans-primary accent-sonans-primary',
        pink: ' border-sonans-pink accent-sonans-pink',
        green: ' border-sonans-green accent-sonans-green',
        black: ' border-sonans-new-black accent-sonans-new-black',
    }

    return (
        <div className="flex items-center">
            <div className="flex items-start py-1 leading-4 w-full">
                <input
                    type="checkbox"
                    className={twJoin(
                        backgroundColorClasses[backgroundColor],
                        'mt-[2px] sm:mt-[2px] md:mt-[5px] bg-transparent border w-3 h-3 sm:w-4 sm:h-4 after:h-4 relative after:w-4 after:absolute cursor-pointer after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%]'
                    )}
                    {...props}
                />
                <label htmlFor={label} className={twJoin(
                    fullWidth ? 'w-full mr-0' : '',
                    'ml-1 md:ml-2 text-[0.625rem] xs:text-[0.75rem] sm:text-[1rem] leading-4 sm:leading-5 md:leading-7'
                )}>
                    {label.charAt(0).toUpperCase() + label.slice(1)}
                </label>
            </div>
        </div>
    )
}
