import { useEffect, useState } from 'react'
import { Container } from './Container'
import { Icon } from './Icon'
import { Logo } from './Logo'
import { Modal } from './Modal'

export function Footer() {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setIsVisible(false)
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    return (
        <footer className="bg-sonans-primary py-16 mt-auto">
            <Container className="flex flex-col gap-8">
                <div className="grid grid-cols-2 gap-8">
                    <div className="flex flex-col gap-4">
                        <h2 className="font-bold text-2xl">Vil du vite mer?</h2>
                        <p>
                            Har du spørsmål om priser, fag eller studiekrav?
                            Eller kanskje du vil ha en timeplan som gjør at din
                            hverdag går opp? Vi hjelper deg, helt gratis.
                        </p>
                        <div>
                            <p>Ta kontakt med oss:</p>
                            <p>
                                <strong>mandag - fredag: 09:00-20:00</strong>
                            </p>
                            <p>
                                <strong>lørdag: 12:00-18:00</strong>
                            </p>
                            <p>
                                <strong>søndag: 12:00-18:00</strong>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 font-bold text-2xl">
                        <a
                            href="https://sonans.no/radgivning"
                            className="self-start hover:bg-white"
                        >
                            Rådgivning
                        </a>
                        <button
                            onClick={() => setIsVisible(!isVisible)}
                            className="self-start hover:bg-white"
                        >
                            Telefon
                        </button>
                        <a
                            href="https://sonans.no/kontaktskole"
                            className="self-start hover:bg-white"
                        >
                            E-post
                        </a>
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    <div className="flex flex-col gap-4">
                        <Logo type="logo" className="w-24" />
                        <div className="text-xs flex flex-col gap-1">
                            <p>SONANS PRIVATGYMNAS AS</p>
                            <p>Orgnr: 980711021</p>
                            <p>Postboks 3603 Bislett, 0136 OSLO</p>
                            <a
                                className="font-bold"
                                href="mailto:kundesenter@sonans.no"
                            >
                                kundesenter@sonans.no
                            </a>
                            <p>Tlf: 915 040 70</p>
                        </div>
                    </div>
                    <div>
                        <p className="text-xs mb-8">
                            For mer informasjon om innsamling av data, se vår{' '}
                            <a
                                href="https://sonans.no/personvern"
                                className="font-bold"
                            >
                                personvernerklæring.
                            </a>
                        </p>
                        <p className="text-xs">
                            For mer informasjon om åpenhetsloven, se vår{' '}
                            <a
                                href="https://lumiinvestor.com/transparency/"
                                className="font-bold"
                            >
                                redegjørelse.
                            </a>
                        </p>
                    </div>
                </div>
                {isVisible && (
                    <Modal onClick={() => setIsVisible(false)}>
                        <div className="bg-white flex flex-col items-center justify-center py-8 px-32 gap-4 font-sonans">
                            <button
                                onClick={() => setIsVisible(false)}
                                className="absolute top-2 right-2"
                            >
                                <Icon type="close" />
                            </button>
                            <h2 className="text-3xl font-bold">
                                Ring oss, så hjelper vi deg!
                            </h2>
                            <a
                                href="tel:91504070"
                                className="text-4xl flex-auto font-bold px-16 border-b-2 border-sonans-primary"
                            >
                                915 04 070
                            </a>
                        </div>
                    </Modal>
                )}
            </Container>
        </footer>
    )
}
