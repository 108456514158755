import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '@/types'
import { EducationalProgramsList } from '@/components/EducationalPrograms/EducationalProgramsList'
import {
    Container,
    ErrorMessage,
    LoadingSpinner,
} from '@/components/UI'
import { fetchEducationalPrograms } from '@/reducers/educationalPrograms'
import { AppLayout } from '@/components/UI/AppLayout'
import { Text } from '@/components/UI/Text'

export const EducationalPrograms = () => {
    const dispatch = useDispatch<AppDispatch>()

    const error = useSelector((state: AppState) => state.educationalPrograms.error)
    const isLoading = useSelector((state: AppState) => state.educationalPrograms.isLoading)
    const data = useSelector((state: AppState) => state.educationalPrograms.data)

    useEffect(() => {
        if (data.length > 0) return
        dispatch(fetchEducationalPrograms())
    }, [dispatch, data])

    if (isLoading)
        return (
            <AppLayout>
                <LoadingSpinner />
            </AppLayout>
        )

    if (!isLoading && error)
        return (
            <AppLayout>
                <ErrorMessage error={error} />
            </AppLayout>
        )

    return (
        <AppLayout>
            <Container className="flex flex-col gap-8 animate-reveal pb-8">
                <Text type="subtitle">Velg utdanningsprogam</Text>
                <EducationalProgramsList />
            </Container>
        </AppLayout>
    )
}
