import { Container } from './Container'
import { Message } from './Message'

interface Props {
    error: string
}

export function ErrorMessage({ error }: Props) {
    return (
        <Container className="py-16">
            <Message color="pink" content={error} />
        </Container>
    )
}
