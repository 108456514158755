import { useSelector } from 'react-redux'
import { AppState } from '@/types'
import { EducationalProgramItem } from './EducationalProgramItem'

export function EducationalProgramsList() {
    const educationalPrograms = useSelector((state: AppState) => {
        return state.educationalPrograms.data.filter(
            (program) =>
                program.programType?.id ===
                state.programTypes.selectedProgramType?.id
        )
    })

    return (
        <div className='border border-sonans-new-black p-4 bg-sonans-green-hue rounded-b-xl rounded-tl-xl'>
            <div className="flex flex-col border border-black rounded-b-xl rounded-tl-xl  bg-white">
                {educationalPrograms.map((program) => (
                    <EducationalProgramItem
                        key={program.id}
                        program={program}
                    />
                ))}
            </div>
        </div>
    )
}
