import reqCourseV2Data from '@/data/requirementCoursesV2.json'
import { Text } from '../Text'
import { OpenInNew } from '@mui/icons-material'

interface Props {
    reqCode: string
}

export function HigherEducationPathRequirements({ reqCode }: Props) {
    let course: {
        name: string
        other: string
        min_avg_grade_reqs: string | null
        preReqs: {
            all: string[][]
            either: string[][]
        }
        reqs: {
            all: string[][]
            either: string[][]
        }
        samo: boolean
    } = {
        name: '',
        other: '',
        preReqs: {
            all: [],
            either: [],
        },
        reqs: {
            all: [],
            either: [],
        },
        samo: false,
        min_avg_grade_reqs: null,
    }

    reqCourseV2Data.forEach((k) => {
        if (k.name === reqCode) {
            course.name = k.name
            course.min_avg_grade_reqs = k.min_avg_grade_reqs as string | null
            if (typeof k.other === 'string') {
                course.other = k.other
            }
            if (k.prereqs !== null) {
                if (k.prereqs.all.length > 0) {
                    course.preReqs.all = k.prereqs.all
                }
                if (k.prereqs.either.length > 0) {
                    k.prereqs.either.forEach((a) => {
                        course.preReqs.either.push(a)
                    })
                }
            }
            if (k.other !== null) {
                course.other = k.other
            }
            if (k.reqs !== null) {
                if (k.reqs.all.length > 0) {
                    course.reqs.all = k.reqs.all
                }
                if (k.reqs.either.length > 0) {
                    k.reqs.either.forEach((a) => {
                        course.reqs.either.push(a)
                    })
                }
            }
            course.samo = k.samo ? true : false
        }
    })

    return (
        <div>
            <Text
                popupContent={
                    course.other && reqCode !== 'GENS' && <p>{course.other}</p>
                }
                className="font-bold"
            >
                Opptakskrav
            </Text>
            <p style={{ paddingBottom: '3px' }}>
                {'Generell studiekompetanse'}{' '}
                {reqCode === 'GENS' ? '' : ` + ${reqCode}`}
            </p>
            {course.samo && (
                <a
                    href={
                        'https://www.samordnaopptak.no/info/opptak/opptak-uhg/spesielle-opptakskrav/liste-over-kravkoder/'
                    }
                    target={'_blank'}
                    className="text-blue-600 flex items-center gap-1"
                    rel="noreferrer"
                >
                    Les om spesifikke opptakskrav på samordnaopptak{' '}
                    <OpenInNew fontSize="small" />
                </a>
            )}

            {!course.samo && (
                <div className="flex flex-col gap-1">
                    {(course.preReqs.all.length > 0 ||
                        course.preReqs.either.length > 0 ||
                        course.min_avg_grade_reqs) && (
                        <Text className="font-bold">Du må dokumentere:</Text>
                    )}

                    {course.preReqs.all.length > 0 &&
                        course.preReqs.all.map((s) => <li>{s}</li>)}

                    {course.preReqs.either.length > 0 && (
                        <li>
                            {course.preReqs.either.length > 0 &&
                                course.preReqs.either.map((s, i) => (
                                    <>
                                        {s.length === 1 && <>{s} </>}
                                        {s.length > 1 && (
                                            <>
                                                (Eller {s.join(' og ')}{' '}
                                                {i === 0 &&
                                                    course.preReqs.either
                                                        .length > 1 &&
                                                    'eller'}{' '}
                                                )
                                            </>
                                        )}
                                    </>
                                ))}
                        </li>
                    )}

                    {course.min_avg_grade_reqs && (
                        <li>{course.min_avg_grade_reqs}</li>
                    )}

                    {course.reqs.all.length > 0 && (
                        <Text className="font-bold flex gap-1">
                            Og{' '}
                            <span className="bg-sonans-new-primary leading-4">alle</span>{' '}
                            følgende krav:
                        </Text>
                    )}
                    
                    {course.reqs.all.length > 0 &&
                        course.reqs.all.map((s, i) => (
                            <div>
                                {s.length > 1 ? (
                                    <li>{s.join(' og ')}</li>
                                ) : (
                                    <li>{s}</li>
                                )}
                            </div>
                        ))}

                    {course.reqs.either.length > 0 && (
                        <Text className="font-bold flex gap-1">
                            Og{' '}
                            <span className="bg-sonans-primary-tint leading-4">ett</span>{' '}
                            av følgende krav:
                        </Text>
                    )}
                    {course.reqs.either.length > 0 &&
                        course.reqs.either.map((s, i) => (
                            <div>
                                {s.length > 1 ? (
                                    <li>{s.join(' og ')}</li>
                                ) : (
                                    <li>{s}</li>
                                )}
                            </div>
                        ))}
                </div>
            )}
        </div>
    )
}
