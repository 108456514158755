import { toFixedDecimal } from '@/reducers/helpers'
import { AppState } from '@/types'
import { useSelector } from 'react-redux'
import { twJoin } from 'tailwind-merge'

type ResultsCardScrollProps = {
    className?: string
}

export const ResultsCardScroll: React.FC<ResultsCardScrollProps> = ({
    className = '',
}) => {
    const twentythreeFiveRule = useSelector(
        (state: AppState) => state.grades.twentythreeFiveRule
    )
    const competitionPoints = useSelector(
        (state: AppState) => state.results.competitionPoints
    )

    const results = twentythreeFiveRule
        ? competitionPoints.twentythreeFiveRule
        : competitionPoints.regular

    return (
        <div
            className={twJoin(
                'bg-sonans-new-green h-20 w-20 rounded-full flex items-center justify-center text-xl font-bold',
                className
            )}
        >
            {toFixedDecimal(results)}
        </div>
    )
}
