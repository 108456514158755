import React from 'react'
import { Link } from 'react-router-dom'

interface HelperText {
    title: string | React.ReactNode
    content: string | React.ReactNode
}

export const helperTextORDF: HelperText = {
    title: 'Kvote for førstegangsvitnemål (ORDF)',
    content: (
        <div>
            <p className={'py-2'}>
                Dersom du er 21 år eller yngre året du søker høyere utdanning,
                og du har førstegangsvitnemål, kan du konkurrere i kvoten for
                førstegangsvitnemål (ORDF).
            </p>

            <p className={'py-2'}>
                Det er da skolepoengene dine du konkurrerer med: karakterpoeng +
                eventuelle realfags- og språkpoeng.
            </p>

            <p className={'py-2'}>
                (Samt kjønnspoeng og poeng for opptaksprøver der det er
                aktuelt.)
            </p>
            <p className={'py-2'}>
                Er du usikker på ditt snitt kan du bruke karakterkalkulatoren.
            </p>
        </div>
    ),
}

export const helperTextORD: HelperText = {
    title: 'Ordinærkvote (ORD)',
    content: (
        <div>
            <p className={'py-2'}>
                Du konkurrerer i ordinær kvote dersom du er 22 år eller eldre,
                eller du mangler førstegangsvitnemål.
            </p>
            <p className={'py-2'}>
                I denne kvoten konkurrerer du med konkurransepoengene dine:
                karakterpoeng+ eventuelle realfags- og språkpoeng, samt alders-
                og tilleggspoeng.
            </p>
            <p className={'py-2'}>
                (Samt kjønnspoeng og poeng for opptaksprøver der det er
                aktuelt.)
            </p>
            <p className={'py-2'}>
                Dersom du også er kvalifisert til å søke i kvoten for
                førstegangsvitnemål (ORDF), vil du bli poengberegnet for både
                skolepoeng og konkurransepoeng og konkurrere i begge kvoter. Er
                du usikker på ditt snitt kan du bruke karakterkalkulatoren.
            </p>
        </div>
    ),
}

export const helperTextAgeAndAddedPoints: HelperText = {
    title: 'Du kan totalt oppnå 8 alderspoeng',
    content: (
        <div>
            <li>
                Med et fullstendig vitnemål, får du alderspoeng fra året du
                fyller 20. Du får 2 poeng for hvert år, året du fyller 23 har du
                8 alderspoeng.
            </li>
            <li>
                Med 23/5-regelen får du alderspoeng fra året du fyller 24. Du
                får 2 poeng for hvert år, året du fyller 27 har du 8
                alderspoeng.
            </li>
        </div>
    ),
}
export const helperTextTwentythreeFileRule: HelperText = {
    title: 'Hva er 23/5 regelen?',
    content: (
        <div>
            <p className={'py-2'}>
                23/5-regelen betyr at du minimum må fylle 23 år det året du
                søker opptak til høyere utdanning, og kan dokumentere 5 år med
                praksis. Hvis du oppfyller disse kravene, trenger du kun disse
                fagene for å få generell studiekompetanse:
            </p>

            <li>Norsk 393 timer</li>
            <li>Matematikk 224 timer</li>
            <li>Naturfag 140 timer</li>
            <li>Historie 140 timer</li>
            <li>Samfunnskunnskap 84 timer</li>
            <li>Engelsk 140 timer</li>

            <p className={'py-2'}>
                Dersom du har tatt fag som gir språk- og realfagspoeng kan du
                også få uttelling for disse med 23/5 regelen.
            </p>
            <p className={'py-2'}>
                Du kan benytte 23/5-regelen selv om du har et fullstendig
                vitnemål dersom det gir deg høyere karaktersnitt.
            </p>
        </div>
    ),
}

export const helperTextSaveCalculations: HelperText = {
    title: 'Du kan lagre din utregning!',
    content: (
        <div>
            <p>
                Velger du å lagre din utregning, vil du finne den igjen{' '}
                <Link className="hover:underline font-bold" to="/">
                    her
                </Link>{' '}
                Du kan også velge å få den tilsendt på e-post. Du kan når som
                helst endre en eksisterende utregning, lagre kopier eller gjøre
                en helt ny utregning.
            </p>
        </div>
    ),
}

export const helperTextFillOutGrades = (
    <div>
        <p className={'py-2'}>
            Både standpunkt og eventuell eksamenskarakter skal være med i
            utregningen.
        </p>

        <p className={'py-2'}>
            Har du flere enn en karakterer i et fag klikker du på + for å legge
            til en karakter.
        </p>

        <p className={'py-2'}>
            Det samme gjelder dersom du f. eks har tatt spansk muntlig +
            skriftlig som privatist: fyll inn en karakter og klikk på pluss for
            å legge til den andre.
        </p>
    </div>
)

export const helperTextStudySelectorPoints = (
    <div>
        <p className={'py-2'}>
            Skriv inn ditt nåværende snitt eller mål. Inkluder eventuelle
            realfags- og språkpoeng, samt alders- og tilleggspoeng dersom du
            skal søke i ordinær kvote.
        </p>
        <p className={'py-2'}>
            Er du 21 år eller yngre og skal søke i kvoten for
            førstegangsvitnemål skal du kun legge inn rent karaktersnitt +
            eventuelle realfags- og språkpoeng.
        </p>
        <p className={'py-2'}>
            Er du usikker på ditt snitt kan du bruke karakterkalkulatoren.
        </p>
    </div>
)

export const helperTextPointsHeader = (
    <div>
        <p className={'py-2'}>
            Defineres som Skolepoeng når du er under 22 år og kvalifiserer for
            førstegangskvote (ORDF).
        </p>
        <p className={'py-2'}>
            Defineres som Konkurransepoeng når du er 22 år eller eldre og
            kvalifiserer for ordinær kvote(ORD) med tilleggspoeng og
            alderspoeng.
        </p>
    </div>
)

export const helperTextUnavailablePoints = (
    <div>
        <p className={'py-2'}>
            Poenggrensene angir den med lavest poengsum som kom inn i opptaket.
            Poenggrensene er en indikasjon på hvor vanskelig det er å komme inn
            på et studium.
        </p>

        <p className={'py-2'}>
            Hvis antall søkere er meget få vil ikke poenggrensen oppgis av
            personvern hensyn.
        </p>
    </div>
)
