import React, { useEffect } from 'react'
import { twJoin } from 'tailwind-merge'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '@/types'
import {
    setActionFeedbackMessage,
    setActionFeedbackType,
    setIsVisible,
} from '@/reducers/actionFeedback'

type ActionFeedbackProps = {
    className?: string
}

const ActionFeedback: React.FC<ActionFeedbackProps> = ({ className = '' }) => {
    const dispatch = useDispatch()

    const isVisible = useSelector((state: AppState) => state.actionFeedback.isVisible)
    const actionFeedbackMessage = useSelector((state: AppState) => state.actionFeedback.actionFeedbackMessage)
    const actionFeedbackType = useSelector((state: AppState) => state.actionFeedback.actionFeedbackType)

    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                dispatch(setIsVisible(false))
            }, 2500)
            setTimeout(() => {
                dispatch(setActionFeedbackMessage(''))
            }, 3000)
            setTimeout(() => {
                dispatch(setActionFeedbackType('success'))
            }, 3500)
            return () => clearTimeout(timer)
        }
    }, [isVisible, dispatch])

    const allClasses = twJoin(
        className,
        'fixed z-[45] left-1/2 transform max-w-xs -translate-x-1/2 rounded-3xl px-4 py-2 sm:px-8 sm:py-4 text-center border-2 border-sonans-new-black transition-all duration-700 ease-in-out',
        actionFeedbackType === 'success'
            ? 'bg-sonans-new-green'
            : 'bg-sonans-new-pink',
        isVisible
            ? 'top-28 sm:top-36'
            : 'top-0 hidden'
    )

    return (
        <div className={allClasses}>
            <p className="text-[0.75rem] sm:text-[1rem] md:text-lg">
                {actionFeedbackMessage}
            </p>
        </div>
    )
}

export default ActionFeedback
