import { useSelector } from 'react-redux'
import { AppState } from '@/types'
import { CURRENT_YEAR } from '@/utils'
import { ResultsCard } from '@/components/Results/ResultsCard'

export function Results() {
    const birthYear = useSelector((state: AppState) => state.experiences.birthYear)
    const twentythreeFiveRule = useSelector((state: AppState) => state.grades.twentythreeFiveRule)

    const {twentythreeFiveRule: twentythreeFivePoints}  = useSelector((state: AppState) => state.results.competitionPoints)

    const regular  = useSelector((state: AppState) => state.results.competitionPoints.regular)

    const competitionPoints = useSelector((state: AppState) => state.results.competitionPoints)
    const overTwentyThree = birthYear && CURRENT_YEAR - +birthYear >= 23

    const exlcudeScience =
        competitionPoints.twentythreeFiveRuleExcludeScience >
        competitionPoints.twentythreeFiveRule

    return (
        <div>
            {twentythreeFiveRule ? (
                <div className="grid grid-cols-1">
                    {exlcudeScience ? (
                        <>
                            <ResultsCard mode="twentythreeFiveRule" />
                            <ResultsCard mode="twentythreeFiveRuleExcludeScience" />
                        </>
                    ) : (
                        <>
                            <ResultsCard mode="twentythreeFiveRule" />
                        </>
                    )}
                </div>
            ) : (
                <div className="grid grid-cols-1">
                    {overTwentyThree && twentythreeFivePoints > regular ? (
                        <>
                            <ResultsCard mode="regular" />
                            <ResultsCard mode="twentythreeFiveRule" />
                        </>
                    ) : (
                        <>
                            <ResultsCard mode="regular" />
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
