import { Experience } from '@/types'

export const CURRENT_YEAR = new Date().getFullYear()

export const EXPERIENCE_CHOICES: Experience[] = [
    'Militæret/siviltjeneste',
    'Minimum 30 studiepoeng',
    'Folkehøgskole',
    'Minimum 60 studiepoeng',
]
