import { useEffect, useRef, useState } from 'react'

interface IUseComponentIsVisible {
    ref: React.LegacyRef<HTMLDivElement>
    isVisible: boolean
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * hook that lets you hide component when clicking outside it
 */
export function useComponentIsVisible(
    initialState: boolean = false
): IUseComponentIsVisible {
    const [isVisible, setIsVisible] = useState<boolean>(initialState)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        function handleClick(e: MouseEvent) {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                setIsVisible(false)
            }
        }

        function handleKeydown(e: KeyboardEvent) {
            if (e.key === 'Escape') {
                setIsVisible(false)
            }
        }

        document.addEventListener('click', handleClick)
        document.addEventListener('keydown', handleKeydown)

        return () => {
            document.removeEventListener('click', handleClick)
            document.removeEventListener('keydown', handleKeydown)
        }
    }, [])

    return { ref, isVisible, setIsVisible }
}
