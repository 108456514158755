import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { API } from '@/utils/config'
import { AppDispatch, IEducationalProgram, IProgramArea } from '@/types'

interface EducationalProgramsState {
    data: IEducationalProgram[]
    selectedEducationalProgram: IEducationalProgram | IProgramArea | null
    isLoading: boolean
    error: string
}

const initialState: EducationalProgramsState = {
    data: [],
    selectedEducationalProgram: null,
    isLoading: false,
    error: '',
}

export const educationalProgramsSlice = createSlice({
    name: 'educationalPrograms',
    initialState,
    reducers: {
        setEducationalPrograms(
            state,
            action: PayloadAction<IEducationalProgram[]>
        ) {
            state.data = action.payload
        },
        setSelectedEducationalProgram(
            state,
            action: PayloadAction<IEducationalProgram | IProgramArea | null>
        ) {
            state.selectedEducationalProgram = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
    },
})

export const {
    setEducationalPrograms,
    setSelectedEducationalProgram,
    setIsLoading,
    setError,
} = educationalProgramsSlice.actions

export function fetchEducationalPrograms() {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setError(''))
            dispatch(setIsLoading(true))
            const { data } = await axios.get(API('educational_programs'))
            dispatch(setEducationalPrograms(data))
        } catch (error: any) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setIsLoading(false))
        }
    }
}
