import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleExperiences, setBirthYear } from '@/reducers/experiences'
import { AppDispatch, AppState } from '@/types'
import { EXPERIENCE_CHOICES } from '@/utils/constants'
import { Input, Checkbox } from '@/components/UI'
import { useState } from 'react'
import { Text } from '@/components/UI/Text'
import { helperTextAgeAndAddedPoints } from '@/helpers/HelperTexts'
import { twJoin } from 'tailwind-merge'
import dataLayer from '@/utils/dataLayer'

const Experiences = () => {
    const experiences = useSelector(
        (state: AppState) => state.experiences.experiences
    )
    const birthYear = useSelector(
        (state: AppState) => state.experiences.birthYear
    )

    const [isOpen, setIsOpen] = useState<boolean>(true)

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const dispatch = useDispatch<AppDispatch>()

    function handleSetBirthYear(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.value.length > 4) return
        dispatch(setBirthYear(e.target.value))
    }

    return (
        <div>
            <section className="flex justify-between">
                <Text
                    type="title"
                    popupTitle={helperTextAgeAndAddedPoints.title}
                    popupContent={helperTextAgeAndAddedPoints.content}
                >
                    Alders- og tilleggspoeng
                </Text>
            </section>
            <div className="flex flex-col gap-2 mb-4">
                <div>
                    <Input
                        noIcon
                        placeholder="Fødselsår"
                        onChange={handleSetBirthYear}
                        value={birthYear}
                        className="w-full"
                        onBlur={() => {
                            dataLayer.push({
                                'event': 'karakterkalkulator_karakterer_fodselsaar',
                                'birthyear': birthYear.toString(),
                            });
                        }}
                    />
                </div>
                <div className="relative w-full">
                    <div
                        className={`
                          flex items-center justify-between border border-sonans-new-black bg-sonans-new-black text-white px-2 xs:py-1 sm:px-4 shadow-hard rounded-t-xl xs:rounded-t-2xl sm:rounded-t-[1.375rem] border-b
                        `}
                        onClick={toggleDropdown}
                    >
                        <Text>Tilleggspoeng</Text>
                    </div>
                    <div
                        className={`z-10 grid grid-cols-2 w-full bg-white border-l border-r border-b border-sonans-new-black px-3 py-1 xs:pb-2 md:px-4 shadow-hard rounded-b-xl xs:rounded-b-2xl sm:rounded-b-[1.375rem]`}
                    >
                        {EXPERIENCE_CHOICES.map((choice, i) => (
                            <Checkbox
                                key={choice}
                                onChange={() => {
                                    dispatch(handleExperiences(choice))
                                }}
                                fullWidth={true}
                                label={choice}
                                value={choice}
                                id={choice}
                                checked={experiences.includes(choice)}
                                backgroundColor="black"
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Experiences
