import { twJoin } from 'tailwind-merge'

interface Props {
    children: React.ReactNode
    htmlFor?: string
}

/**
 * Label component
 */
export function Label({ children, htmlFor }: Props) {
    return (
        <label htmlFor={htmlFor} className={twJoin('block text-xs md:text-sm')}>
            {children}
        </label>
    )
}
