import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppState, ICourse, ICourseGrade, IGrade } from '@/types'

export interface GradeState {
    grades: ICourseGrade[]
    twentythreeFiveRule: boolean
}

export const initialState: GradeState = {
    grades: [],
    twentythreeFiveRule: false,
}

export const gradesSlice = createSlice({
    name: 'grades',
    initialState,
    reducers: {
        setGrades(state, action: PayloadAction<ICourseGrade[]>) {
            state.grades = action.payload
        },
        appendGrades(state, action: PayloadAction<ICourseGrade>) {
            state.grades.push(action.payload)
        },
        pushGrade(
            state,
            action: PayloadAction<{ course: ICourse; grade: IGrade }>
        ) {},
        updateGrade(state, action: PayloadAction<ICourseGrade>) {
            const currentGrade = state.grades.find(
                (grade) => grade.course.id === action.payload.course.id
            )

            if (!currentGrade) return

            currentGrade.grades = action.payload.grades
        },
        removeGrade(state, action: PayloadAction<ICourse>) {
            const currentGradeIndex = state.grades.findIndex(
                (grade) => grade.course.id === action.payload.id
            )
            state.grades.splice(currentGradeIndex, 1)
        },
        resetGrades(state) {
            state.grades = []
        },
        setForm(state, action) {
            return action.payload
        },
        setTwentyThreeFiveRule(state, action: PayloadAction<boolean>) {
            state.twentythreeFiveRule = action.payload
        },
    },
})

export const {
    setGrades,
    appendGrades,
    updateGrade,
    removeGrade,
    resetGrades,
    pushGrade,
    setForm,
    setTwentyThreeFiveRule,
} = gradesSlice.actions

// TODO: Find simpler way to handle grades, too many layers of logic and state management in multiple components and reducers
// This function exsits to handle the selection of grades for the given course. It is triggered when a user selects a grade or exam grade for a course.
export function handleGrades(payload: { course: ICourse; grade: IGrade }) {
    return function (dispatch: AppDispatch, getState: () => AppState) {
        // Get the current grades state from Redux
        const { grades } = getState().grades

        // Find the course in the grades state that matches the course in the payload
        const existingCourse = grades.find(
            (grade) => grade.course.id === payload.course.id
        )

        if (existingCourse) {
            const foundGrade = existingCourse?.grades?.find(
                (grade) =>
                    grade.type === payload.grade.type &&
                    grade.examType === payload.grade.examType
            )

            if (foundGrade) {
                // if the grade is 0, remove it
                if (payload.grade.grade === 0) {

                    console.log(payload);

                    const updatedGrades = existingCourse.grades.filter(
                        (grade) =>
                            grade.type !== payload.grade.type &&
                            grade.examType !== payload.grade.examType
                    )


                    dispatch(
                        updateGrade({
                            course: payload.course,
                            grades: updatedGrades,
                        })
                    )

                    return
                }

                // if the grade already exists, update it
                const updatedGrades = existingCourse.grades.map((grade) => {
                    if (
                        grade.type === payload.grade.type &&
                        grade.examType === payload.grade.examType
                    ) {
                        return payload.grade
                    }
                    return grade
                })
                dispatch(
                    updateGrade({
                        course: payload.course,
                        grades: updatedGrades,
                    })
                )
            } else {
                // if the grade doesn't exist, add it
                const updatedGrades = [...existingCourse.grades, payload.grade]
                dispatch(
                    updateGrade({
                        course: payload.course,
                        grades: updatedGrades,
                    })
                )
            }
        } else {
            // If the course doesn't exist in the grades state, add it
            dispatch(
                appendGrades({
                    course: payload.course,
                    grades: [payload.grade],
                })
            )
        }
    }
}
