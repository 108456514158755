import { Input } from '@/components/UI/Input'
import { AppLayout } from '@/components/UI/AppLayout'
import { Container, LoadingSpinner } from '@/components/UI'
import { Text } from '@/components/UI/Text'
import { BookCounseling } from '@/components/UI/BookCounseling'
import { useGetStudyFieldsQuery } from '@/api/api'
import Pagination from '@/components/UI/HigherEducationPath/Pagination'
import { useState } from 'react'
import HigherEducationPathList from '@/components/UI/HigherEducationPath/HigherEducationPathList'
import CategoriesFiltration from '@/components/UI/HigherEducationPath/CategoriesFiltration'
import { IStudyCategory } from '@/types'
import dataLayer from '@/utils/dataLayer'

export const StudySelector = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')
    const [selectedCategories, setSelectedCategories] = useState<
        IStudyCategory[]
    >([])

    const { data: studyFields, isLoading } = useGetStudyFieldsQuery({
        page: currentPage,
        search,
        study_category_id: selectedCategories
            .map((category) => category.id)
            .join(','),
    })

    if (isLoading)
        return (
            <AppLayout>
                <LoadingSpinner />
            </AppLayout>
        )

    if (!studyFields) return null

    return (
        <AppLayout>
            <Container size="lg" className="animate-reveal">
                <div className="font-light">
                    <div className="mb-3 py-4">
                        <Text className="text-4xl" type="title">
                            Se dine studiemuligheter
                        </Text>

                        <Text className="py-4">
                            {`Her finner du studieoversikten til Samordna opptak. Den viser opptakskrav og tidligere poenggrenser for ${studyFields.hits_total} utdanninger ved offentlige høgskoler og universiteter.`}
                        </Text>
                        <div className="flex justify-between items-end gap-2">
                            <div className="w-full md:w-96">
                                <Input
                                    value={search}
                                    placeholder="Søk etter utdanninger"
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                        setCurrentPage(1)
                                    }}
                                    className="w-full"
                                    onBlur={() => {
                                        dataLayer.push({
                                            'event': 'karakterkalkulator_studiemuligheter_soketekst',
                                            'searchterm': search
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <CategoriesFiltration
                onClick={() => {
                    setSearch('')
                    setCurrentPage(1)
                }}
                onChange={(categories) => {
                    setSelectedCategories(categories)
                    setCurrentPage(1)
                }}
                searchPhrases={search}
            />
            <div className="animate-reveal md:max-w-lg lg:max-w-xl mx-auto">
                <HigherEducationPathList studyFields={studyFields.data} />
            </div>
            <div className="animate-reveal md:max-w-lg lg:max-w-xl mx-auto">
                <Pagination
                    totalRecords={studyFields.hits_total}
                    currentPage={currentPage}
                    numOfPages={studyFields.total_pages}
                    recordsPerPage={10}
                    setCurrentPage={setCurrentPage}
                />
                <div className="my-4">
                    Antall sider med ditt filter: {studyFields.total_pages}{' '}
                </div>
            </div>
            <BookCounseling textType={'study'} />
        </AppLayout>
    )
}
