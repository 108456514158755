interface Props {
    children: React.ReactNode
    onClick: () => void
}

export function Overlay({ children, onClick }: Props) {
    return (
        <div className="fixed top-0 left-0 h-screen w-screen z-[100] flex items-center justify-center animate-scale">
            <div
                onClick={onClick}
                className="bg-[rgba(0,0,0,0.6)] fixed h-full w-full"
            ></div>
            <div className="fixed">{children}</div>
        </div>
    )
}
