import React, { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material'
import { GradeItem } from './GradeItem'
import { twJoin } from 'tailwind-merge'
import { Text } from '../UI'
import { AppDispatch, AppState, ICourse, IGrade, TExamType } from '@/types'
import { useDispatch, useSelector } from 'react-redux'
import { handleGrades } from '@/reducers/grades'

interface Props {
    course: ICourse
    selectedExamType: TExamType
    disabled: boolean
    onRemoveExam: (exam: TExamType) => void
}

export const ExtraGradeRow: React.FC<Props> = ({
    course,
    selectedExamType,
    disabled,
    onRemoveExam,
}) => {
    const [selectedExamGrade, setSelectedExamGrade] = useState<IGrade>({
        examType: '',
        grade: 0,
        type: 'exam',
    })

    const initialRender = React.useRef(true)

    const gradeState = useSelector((state: AppState) => state.grades.grades)

    useEffect(() => {
        if (initialRender.current) {
            const foundCourse = gradeState.find(
                (grade) => grade.course.id === course.id
            )

            if (foundCourse) {
                const foundGrade = foundCourse?.grades?.find(
                    (grade) =>
                        grade.type === 'exam' &&
                        grade.examType === selectedExamType
                )
                if (foundGrade) {
                    setSelectedExamGrade(foundGrade)
                }
            }

            initialRender.current = false
        }
    }, [course.id, gradeState, selectedExamType])

    const dispatch = useDispatch<AppDispatch>()

    const grades = Array.from({ length: 6 }, (_, index) => index + 1)

    return (
        <div className="flex justify-between flex-wrap md:flex-nowrap items-center mt-4 animate-reveal">
            <div
                className={twJoin(
                    'w-full md:w-1/2',
                    disabled ? 'opacity-30' : ''
                )}
            >
                <Text className="mb-2 md:mb-0">{selectedExamType}</Text>
            </div>

            <div className="flex justify-between w-full items-center">
                <div className="flex flex-col w-[160px] xs:w-[200px] sm:w-[250px]">
                    <section
                        className={`grid grid-cols-6 border w-full border-black rounded-full bg-white z-10 transition-transform duration-300 ease-in-out`}
                    >
                        {grades.map((grade) => (
                            <GradeItem
                                type="exam"
                                grade={grade}
                                key={grade}
                                selectedGrade={selectedExamGrade.grade}
                                onClick={() => {
                                    if (disabled) return

                                    // if the selected grade is the same as the grade that was clicked, we remove the grade
                                    if (selectedExamGrade.grade === grade) {
                                        setSelectedExamGrade({
                                            grade: 0,
                                            type: 'exam',
                                            examType: selectedExamType,
                                        })
                                        dispatch(
                                            handleGrades({
                                                grade: {
                                                    grade: 0,
                                                    type: 'exam',
                                                    examType: selectedExamType,
                                                },
                                                course: course,
                                            })
                                        )
                                        return
                                    }

                                    const newGrade: IGrade = {
                                        examType: selectedExamType,
                                        grade,
                                        type: 'exam',
                                    }

                                    setSelectedExamGrade(newGrade)

                                    dispatch(
                                        handleGrades({
                                            course,
                                            grade: newGrade,
                                        })
                                    )
                                }}
                            />
                        ))}
                    </section>
                </div>

                <button
                    onClick={() => {
                        if (disabled) return

                        setSelectedExamGrade({
                            examType: selectedExamType,
                            grade: 0,
                            type: 'exam',
                        })

                        dispatch(
                            handleGrades({
                                course,
                                grade: {
                                    examType: selectedExamType,
                                    grade: 0,
                                    type: 'exam',
                                },
                            })
                        )

                        onRemoveExam(selectedExamType)
                    }}
                    className={`border border-black rounded-full aspect-square shadow-hard-sm flex justify-center items-center w-4 xs:w-7 sm:w-9 bg-sonans-new-pink`}
                >
                    <Close fontSize="inherit" />
                </button>

                <div className="w-[13px]"></div>
            </div>
        </div>
    )
}
