import { Modal } from '.'
import React, { useEffect, useState } from 'react'
import InfoIcon from '@/assets/info.svg?react'
import Paragraph from '@editorjs/paragraph'



type Props = {
    title?: string | React.ReactNode
    content: string | React.ReactNode
    color?: 'yellow' | 'black'
}

/**
 * Tooltip component
 */
export function Popup({ content, title, color = 'yellow' }: Props) {
    const [isVisible, setIsVisible] = useState(false)


    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setIsVisible(false)
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    return (
        <>
            {isVisible && (
                <Modal onClick={() => setIsVisible(false)}>
                    <div className="fixed w-[90%] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-50 bg-sonans-new-green border border-black rounded-3xl pt-8 px-8 pb-2 md:w-1/3 xl:w-[48rem] 2xl:w-1/4">
                        <div>
                            {title && (
                                <h3 className="text-base md:text-lg font-bold mb-4">
                                    {title}
                                </h3>
                            )}
                            <div className="text-sm md:text-base pb-4">
                                {content}
                            </div>
                            <div className="flex justify-end">
                                <button
                                    className="uppercase font-bold hover:bg-white transition-all"
                                    onClick={() => setIsVisible(false)}
                                >
                                    Lukk
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <button
                onClick={() => {
                    setIsVisible(true)
                }}
            >
                <InfoIcon className="w-5" />
            </button>
        </>
    )
}
