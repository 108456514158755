import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCourse } from '@/reducers/courses'
import { AppDispatch, AppState, ICourse } from '@/types'
import { CourseInfo } from './CourseInfo'
import { useComponentIsVisible } from '@/hooks/useComponentIsVisible'
import { twJoin } from 'tailwind-merge'
import { Icon, Popup } from '../UI'
import { Input } from '../UI/Input'
import dataLayer from '@/utils/dataLayer'
import { i } from 'vite/dist/node/types.d-aGj9QkWt'

let initialRender = true

export function CourseSearch() {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [isSearching, setIsSearching] = useState<boolean>(false)

    const dispatch = useDispatch<AppDispatch>()

    const { isVisible, setIsVisible } = useComponentIsVisible()

    useEffect(
        function () {
            if (initialRender) {
                initialRender = false
                return
            }

            setIsVisible(isSearching && searchTerm.length > 0)
        },
        [searchTerm, isSearching, setIsVisible]
    )

    const additionalCourse = useSelector(
        (state: AppState) => state.courses.additionalCourses
    )

    const courses = additionalCourse.filter(
        (course) =>
            course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            course.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
            course.replacedCourses.some((replacedCourse) =>
                replacedCourse.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            ) ||
            course.replacedCourses.some((replacedCourse) =>
                replacedCourse.code
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
    )

    // Used to filter away search results based on name
    // as in when Udir still contains that record but we need to filter it out as we're ahead of them
    // with deprecations
    const isExcludedCourse = (course: ICourse) => {
        const excludedNames = ['Biologi 3', 'Fysikk 3']
        return !excludedNames.includes(course.name)
    }

    // used to auto-correct known phrases into phrases that yields expected results
    // const correctSpellingOrApplyShorthands = (txt: string) => {
    //     var text = txt
    //     var returnedPhrase = txt
    //
    //     if (text.toLowerCase().includes('gym')) {
    //         returnedPhrase = 'kropp'
    //     }
    //
    //     if (
    //         text.toLowerCase().includes('p matte') ||
    //         text.toLocaleLowerCase().includes('pmatte') ||
    //         text.toLocaleLowerCase().includes('matte')
    //     ) {
    //         returnedPhrase = 'mate'
    //     }
    //
    //     if (
    //         text.toLowerCase().includes('spansk 2') ||
    //         text.toLocaleLowerCase().includes('spansk nivå') ||
    //         text.toLocaleLowerCase().includes('spansk ii')
    //     ) {
    //         returnedPhrase = 'spansk'
    //     }
    //
    //     setSearchTerm(returnedPhrase)
    // }

    return (
        <div className="relative w-full">
            <Input
                onFocus={() => setIsSearching(true)}
                onChange={(e) => setSearchTerm(e.target.value)}
                type="text"
                placeholder="Matematikk R1 eller REA3056"
                value={searchTerm}
                className={'outline-none w-full '}
            />
            {isVisible && courses.length > 0 && (
                <div className="overflow-y-scroll scrollbar-none mt-4 absolute w-full max-h-96 border border-sonans-new-gray rounded-3xl animate-reveal z-10">
                    {courses
                        .filter((course) => isExcludedCourse(course))
                        .map((course, index) => (
                            <button
                                onClick={() => {
                                    dataLayer.push({
                                        event: 'karakterkalkulator_karakterer_soketekst',
                                        searchterm: searchTerm,
                                    })
                                    dispatch(addCourse(course))
                                    setIsSearching(false)
                                    setSearchTerm('')
                                }}
                                className={twJoin(
                                    'p-6 tex-lg flex justify-between cursor-pointer hover:bg-gray-200 transition-all first:rounded-t-3xl last:rounded-b-3xl w-full',
                                    index % 2 !== 0 ? 'bg-gray-100' : 'bg-white'
                                )}
                                key={course.id}
                            >
                                <div className="flex gap-2 w-full">
                                    <span>{course.name}</span>
                                    {course.replacedCourses.length > 0 && (
                                        <Popup
                                            content={
                                                <CourseInfo course={course} />
                                            }
                                        />
                                    )}
                                </div>
                                <Icon type="plus" />
                            </button>
                        ))}
                </div>
            )}
        </div>
    )
}
