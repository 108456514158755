import { AppState } from '@/types'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const NavBar = () => {
    const location = useLocation()
    const { referenceCode } = useParams()
    const navigate = useNavigate()

    const educationalProgram = useSelector((state: AppState) => state.educationalPrograms.selectedEducationalProgram)
    const programType = useSelector((state: AppState) => state.programTypes.selectedProgramType)

    // the slash needs to be included in mainPath prop
    const NAVITEMS = [
        {
            name: 'Karakterkalkulator',
            mainPath: '/start',
            additionalPaths: ['utdanningsprogram', 'karakterer', 'start'],
        },
        {
            name: 'Studiemuligheter',
            mainPath: '/studiemuligheter',
            additionalPaths: ['studiemuligheter'],
        },
    ]

    return (
        <div className="justify-center scale-[0.8] md:scale-100">
            <nav className="text-base bg-sonans-primary/50 rounded-full border-2 border-sonans-primary/50">
                <ul className="flex justify-between">
                    {NAVITEMS.map((item) => (
                        <li key={item.name}>
                            <button
                                className={`justify-center w-[10rem] h-[2rem] items-center gap-1 text-center ${
                                    location.pathname === item.mainPath ||
                                    (item.additionalPaths &&
                                        item.additionalPaths.some((path) =>
                                            location.pathname.includes(path)
                                        ))
                                        ? 'bg-white rounded-full duration-75'
                                        : ''
                                }`}
                                onClick={() => {
                                    if (referenceCode) {
                                        switch (item.name) {
                                            case 'Karakterkalkulator':
                                                // If the user has already selected a program, navigate to the grades page to avoid having to start over
                                                if (educationalProgram || programType) {
                                                    navigate('/karakterer')
                                                    break
                                                }
                                                navigate(`/start`)
                                                break
                                            case 'Studiemuligheter':
                                                navigate(`/studiemuligheter`)
                                                break
                                        }
                                    } else {
                                        switch (item.name) {
                                            case 'Karakterkalkulator':
                                                // If the user has already selected a program, navigate to the grades page to avoid having to start over
                                                if (educationalProgram || programType) {
                                                    navigate('/karakterer')
                                                    break
                                                }
                                                navigate(`/start`)
                                                break
                                            case 'Studiemuligheter':
                                                navigate(`/studiemuligheter`)
                                                break
                                        }
                                    }
                                }}
                            >
                                <p>{item.name}</p>
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    )
}

export default NavBar
