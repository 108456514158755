import {
    KeyboardArrowLeftOutlined,
    KeyboardArrowRightOutlined,
} from '@mui/icons-material'
import { Button } from '../Button'

type PaginationProps = {
    totalRecords: number
    currentPage: number
    numOfPages: number
    recordsPerPage: number
    setCurrentPage: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
    totalRecords,
    currentPage,
    numOfPages,
    recordsPerPage,
    setCurrentPage,
}) => {
    const showPaginationNumbers = (totalRecords: number) => {
        const pages: number[] = []
        if (totalRecords > 0) {
            const showMaxPages: number = 5
            const numOfPages: number = Math.ceil(totalRecords / recordsPerPage)
            let startPage: number, endPage: number

            if (numOfPages <= showMaxPages) {
                startPage = 1
                endPage = numOfPages
            } else {
                const halfMaxPages = Math.floor(showMaxPages / 2)
                if (currentPage <= halfMaxPages) {
                    startPage = 1
                    endPage = showMaxPages
                } else if (currentPage + halfMaxPages >= numOfPages) {
                    startPage = numOfPages - showMaxPages + 1
                    endPage = numOfPages
                } else {
                    startPage = currentPage - halfMaxPages
                    endPage = currentPage + halfMaxPages
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                if (i > 0 && i <= numOfPages) {
                    pages.push(i)
                }
            }
        }
        return showRenderPageNumbers(pages)
    }

    const showRenderPageNumbers = (pages: number[]) => {
        if (pages && pages.length > 0) {
            return pages.map((pageNumber, index) => (
                <Button
                    smallShadow
                    key={index}
                    mode="pagination"
                    size="none"
                    icon="none"
                    onClick={() => setCurrentPage(pageNumber)}
                    className={`
                        text-[0.8rem] sm:text-[1rem]
                        ${pageNumber === currentPage ? 'font-bold border-black bg-sonans-primary hover:bg-sonans-primary' : ''}
                    `}
                >
                    {pageNumber}
                </Button>
            ))
        } else {
            return <Button>1</Button>
        }
    }

    const previousPagePressed = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPagePressed = () => {
        if (currentPage < numOfPages) {
            setCurrentPage(currentPage + 1)
        }
    }

    if (totalRecords === 0) return null

    return (
        <div className="flex justify-center items-center mt-4 gap-1 md:gap-2">
            {currentPage !== 1 && (
                <Button
                    smallShadow
                    mode="pagination"
                    size="none"
                    icon="none"
                    className="scale-[0.9]"
                    onClick={() => previousPagePressed()}
                >
                    <p className="text-[0.8rem] sm:text-[1.3rem] -translate-y-[2px]">
                        <KeyboardArrowLeftOutlined fontSize="inherit" />
                    </p>
                </Button>
            )}
            {showPaginationNumbers(totalRecords)}
            {currentPage !== Math.ceil((totalRecords / recordsPerPage)) && (
                <Button
                    smallShadow
                    mode="pagination"
                    size="none"
                    icon="none"
                    className="scale-[0.9]"
                    onClick={() => nextPagePressed()}
                >
                    <p className="text-[0.8rem] sm:text-[1.3rem] -translate-y-[2px]">
                        <KeyboardArrowRightOutlined fontSize="inherit" />
                    </p>
                </Button>
            )}
        </div>
    )
}

export default Pagination
