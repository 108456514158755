import { twJoin, twMerge } from 'tailwind-merge'
import { Popup } from './Popup'

interface Props {
    children: React.ReactNode
    type?: 'title' | 'subtitle' | 'body'
    center?: boolean
    className?: string
    popupTitle?: string | React.ReactNode
    popupContent?: string | React.ReactNode
}

export function Text({
    children,
    type = 'body',
    className = '',
    center = false,
    popupTitle = '',
    popupContent,
}: Props) {
    const typeClasses: { [key: string]: string } = {
        title: 'text-[1.25rem] xs:text-[1.5rem] sm:text-[1.625rem] md:text-[1.75rem] leading-6 sm:leading-7 md:leading-9 lg:leading-10 my-2',
        subtitle:
            'text-[1.125rem] xs:text-[1.25rem] sm:text-[1.375rem] md:text-[1.5rem] leading-5 sm:leading-6 md:leading-8 lg:leading-10 my-1',
        body: 'text-[0.625rem] xs:text-[0.75rem] sm:text-[1rem] leading-4 sm:leading-5 md:leading-7',
    }

    const containerClass = twJoin(
        typeClasses[type],
        center && 'text-center',
        popupContent && 'flex items-center gap-2',
        className
    )

    return (
        <p className={twMerge('flex items-center', containerClass)}>
            {children}
            {popupContent && <Popup title={popupTitle} content={popupContent} />}
        </p>
    )
}
