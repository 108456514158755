import ReactDOM from 'react-dom/client'
import { App } from './App'
import './main.css'
import { Provider } from 'react-redux'
import { store } from './reducers'
import { BrowserRouter } from 'react-router-dom'

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9ab0e948196d53e71f36fbbeaf790c15@o4508091849572352.ingest.de.sentry.io/4508092886941776",
  enabled: import.meta.env.VITE_ENV === 'production',
  integrations: [
    Sentry.browserTracingIntegration(), 
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const root = ReactDOM.createRoot(
    document.getElementById('Karakterkalkulator') as HTMLElement
)
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)
