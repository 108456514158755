import React from 'react'
import { twJoin } from 'tailwind-merge'

interface Props {
    grade: number
    onClick: (grade: number) => void
    selectedGrade: number
    className?: string
    type: 'grade' | 'exam'
}

/**
 * Component that displays a single grade
 */
export function GradeItem({
    grade,
    onClick,
    selectedGrade,
    className = '',
    type = 'grade',
}: Props) {
    const gradeStyles = {
        grade: 'bg-sonans-new-primary rounded-full border border-black m-[2px] shadow-hard-sm active:shadow-[0px_0px_0px] active:translate-y-1 active:translate-x-1 duration-75',
        exam: 'bg-sonans-new-green rounded-full border border-black m-[2px] shadow-hard-sm active:shadow-[0px_0px_0px] active:translate-y-1 active:translate-x-1 duration-75',
    }

    return (
        <button
            className={twJoin(
                selectedGrade !== 0 && selectedGrade === grade && gradeStyles[type],
                'aspect-square',
                className
            )}
            onClick={() => {
                onClick(grade)
            }}
        >
            <p className="text-xs sm:text-sm md:text-lg">{grade}</p>
        </button>
    )
}
