import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { API } from '@/utils/config'
import { AppDispatch, IProgamType } from '@/types'

interface ProgramTypeState {
    data: IProgamType[]
    selectedProgramType: IProgamType | null
    isLoading: boolean
    error: string
}

const initialState: ProgramTypeState = {
    data: [
        {
            id: 2,
            name: 'Studieforberedende',
        },
        {
            id: 1,
            name: 'Yrkesfag',
        },
        {
            id: 3,
            name: '23/5-regelen',
        },
    ],
    selectedProgramType: null,
    isLoading: false,
    error: '',
}

export const programTypesSlice = createSlice({
    name: 'programTypes',
    initialState,
    reducers: {
        setProgramTypes(state, action: PayloadAction<IProgamType[]>) {
            state.data = action.payload
        },
        setSelectedProgramType(
            state,
            action: PayloadAction<IProgamType | null>
        ) {
            state.selectedProgramType = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
    },
})

export const {
    setProgramTypes,
    setSelectedProgramType,
    setIsLoading,
    setError,
} = programTypesSlice.actions

export function fetchProgramTypes() {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(setError(''))
            dispatch(setIsLoading(true))
            const { data } = await axios.get(API('program_types'))
            dispatch(setProgramTypes(data))
        } catch (error: any) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setIsLoading(false))
        }
    }
}
