import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface ReferenceCodeState {
    code: string,
    userAlias: string,
    isLoading: boolean
    error: string
}

const initialState: ReferenceCodeState = {
    code: '',
    userAlias: '',
    isLoading: false,
    error: ''
}

export const referenceCodeSlice = createSlice({
    name: "referenceCode",
    initialState,
    reducers: {
        setReferenceCode(state, action: PayloadAction<string>) {
            state.code = action.payload
        },
        setUserAlias(state, action: PayloadAction<string>){
            state.userAlias = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload
        }
    }
})

export const {
    setReferenceCode,
    setUserAlias,
    setError,
    setIsLoading
} = referenceCodeSlice.actions


