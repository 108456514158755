import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppDispatch, AppState } from '@/types'
import { ProgramTypes } from '@/pages/ProgramTypes'
import { Courses } from './pages/Courses'
import { handleCalculations } from './reducers/results'
import { EducationalPrograms } from './pages/EducationalPrograms'
import { StudySelector } from './pages/StudySelector'
import {
    setProgramTypes,
    setSelectedProgramType,
} from '@/reducers/programTypes'
import {
    setEducationalPrograms,
    setSelectedEducationalProgram,
} from '@/reducers/educationalPrograms'
import { setAdditionalCourses, setDefaultCourses } from '@/reducers/courses'
import { setGrades, setTwentyThreeFiveRule } from '@/reducers/grades'
import { setBirthYear, setExperiences } from '@/reducers/experiences'
import { Favorites } from './pages/Favorites'

let initialRender = true

export function App() {
    const grades = useSelector((state: AppState) => state.grades)
    const experiences = useSelector((state: AppState) => state.experiences)
    const educationalPrograms = useSelector(
        (state: AppState) => state.educationalPrograms
    )
    const programTypes = useSelector((state: AppState) => state.programTypes)
    const courses = useSelector((state: AppState) => state.courses)
    const favoriteStudyFields = useSelector(
        (state: AppState) => state.favoriteStudyFields
    )

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(handleCalculations())
    }, [grades, experiences, courses.defaultCourses, dispatch])

    useEffect(() => {
        if (initialRender) {
            initialRender = false
            return
        }

        const lastUpdate = new Date().getTime()

        localStorage.setItem(
            'karakterkalkulator',
            JSON.stringify({
                lastUpdate,
                grades,
                experiences,
                courses,
                educationalPrograms,
                programTypes,
                favoriteStudyFields,
            })
        )
    }, [
        grades,
        experiences,
        educationalPrograms,
        programTypes,
        courses,
        favoriteStudyFields,
    ])

    useEffect(() => {
        let localData

        if (localStorage.getItem('karakterkalkulator')) {
            localData = JSON.parse(
                localStorage.getItem('karakterkalkulator') || ''
            )

            const hoursInMilliseconds = 168 * 60 * 60 * 1000
            const savedTimestamp = localData.lastUpdate
            const currentTimestamp = new Date().getTime()

            if (currentTimestamp - savedTimestamp >= hoursInMilliseconds) {
            } else {
                dispatch(setProgramTypes(localData.programTypes.data))
                dispatch(
                    setSelectedProgramType(
                        localData.programTypes.selectedProgramType
                    )
                )

                dispatch(
                    setEducationalPrograms(localData.educationalPrograms.data)
                )
                dispatch(
                    setSelectedEducationalProgram(
                        localData.selectedEducationalProgram
                    )
                )

                dispatch(setDefaultCourses(localData.courses.defaultCourses))
                dispatch(
                    setAdditionalCourses(localData.courses.additionalCourses)
                )

                dispatch(setGrades(localData.grades.grades))
                dispatch(setTwentyThreeFiveRule(localData.twentythreeFiveRule))

                dispatch(setExperiences(localData.experiences.experiences))
                dispatch(setBirthYear(localData.experiences.birthYear))
            }
        } else {
            return
        }
    }, [dispatch])

    return (
        <div>
            <Routes>
                <Route path="/" element={<Navigate replace to="/start" />} />
                <Route path="/start" element={<ProgramTypes />} />
                <Route
                    path="/start/:referenceCode"
                    element={<ProgramTypes />}
                />
                <Route
                    path="/utdanningsprogram"
                    element={<EducationalPrograms />}
                />
                <Route path="/karakterer" element={<Courses />} />
                <Route
                    path="/karakterer/:referenceCode"
                    element={<Courses />}
                />
                <Route path="/studiemuligheter" element={<StudySelector />} />
                <Route
                    path="/studiemuligheter/favoritter"
                    element={<Favorites />}
                />
            </Routes>
        </div>
    )
}
