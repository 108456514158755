import { IStudyCategory, StudyFieldResponse } from '@/types'
import { API_URL } from '@/utils'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
    endpoints: (builder) => ({
        getStudyFields: builder.query<
            StudyFieldResponse,
            { page?: number; search?: string; study_category_id?: string }
        >({
            query: ({ page, search, study_category_id }) => ({
                url: 'study_fields',
                params: {
                    page,
                    search,
                    study_category_id,
                },
            }),
        }),
        getStudyCategories: builder.query<IStudyCategory[], void>({
            query: () => 'study_categories',
        }),
    }),
})

export const { useGetStudyFieldsQuery, useGetStudyCategoriesQuery } = api
