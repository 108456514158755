import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ActionFeedbackState {
    isVisible: boolean,
    actionFeedbackMessage: string,
    actionFeedbackType: string,
}

const initialState: ActionFeedbackState = {
    isVisible: false,
    actionFeedbackMessage: '',
    actionFeedbackType: 'success',
}

export const actionFeedbackSlice = createSlice({
    name: 'actionFeedback',
    initialState,
    reducers: {
        setIsVisible(state, action: PayloadAction<boolean>) {
            state.isVisible = action.payload
        },
        setActionFeedbackMessage(state, action: PayloadAction<string>) {
            state.actionFeedbackMessage = action.payload
        },
        setActionFeedbackType(state, action: PayloadAction<string>) {
            state.actionFeedbackType = action.payload
        },
        resetActionFeedback(state) {
            setIsVisible(false)
            setActionFeedbackMessage('')
            setActionFeedbackType('success')
        },
    },
})

export const { setIsVisible } = actionFeedbackSlice.actions
export const { setActionFeedbackMessage } = actionFeedbackSlice.actions
export const { setActionFeedbackType } = actionFeedbackSlice.actions
export const { resetActionFeedback } = actionFeedbackSlice.actions
