import React, { useEffect, useState } from 'react'
import { Container } from '../Container'
import { Text } from '../Text'
import useEmblaCarousel from 'embla-carousel-react'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { capitalize } from '@/utils'
import { useGetStudyCategoriesQuery } from '@/api/api'
import { IStudyCategory } from '@/types'

type CategoriesFiltrationProps = {
    onChange: (categories: IStudyCategory[]) => void
    searchPhrases: string
    onClick: () => void
}

const CategoriesFiltration: React.FC<CategoriesFiltrationProps> = ({
    onChange,
    searchPhrases,
    onClick,
}) => {
    const [selectedCategories, setSelectedCategories] = useState<
        IStudyCategory[]
    >([])
    const { data: categories } = useGetStudyCategoriesQuery()

    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true,
        align: 'start',
    })

    function handleCategorySelection(category: IStudyCategory) {
        if (
            selectedCategories
                .map((category) => category.id)
                .includes(category.id)
        ) {
            const newSelectedCategories = selectedCategories.filter(
                (selectedCategory) => selectedCategory.id !== category.id
            )
            setSelectedCategories(newSelectedCategories)
            onChange(newSelectedCategories)
        } else {
            const newSelectedCategories = [...selectedCategories, category]

            setSelectedCategories(newSelectedCategories)
            onChange(newSelectedCategories)
        }
    }

    if (!categories) return null

    return (
        <div>
            <div className="bg-sonans-blue-hue py-4">
                <Container size="lg" className="animate-reveal">
                    <Text type="title">Utdanningsområder</Text>
                    <div className="flex items-center gap-2">
                        <button
                            onClick={() => {
                                if (emblaApi) {
                                    emblaApi.scrollPrev()
                                }
                            }}
                        >
                            <ArrowBackIos />
                        </button>
                        <div
                            className="embla overflow-hidden w-full"
                            ref={emblaRef}
                        >
                            <div className="embla__container w-full flex">
                                {categories.map((category) => (
                                    <button
                                        key={category.id}
                                        onClick={() => {
                                            handleCategorySelection(category)
                                        }}
                                        className={`karakterkalkulator_studiemuligheter_filterutdanningsomraader px-4 w-auto shrink-0 py-1 border border-black rounded-3xl text-sm mr-2
                                            ${
                                                selectedCategories
                                                    .map(
                                                        (category) =>
                                                            category.id
                                                    )
                                                    .includes(category.id)
                                                    ? 'bg-sonans-new-green'
                                                    : 'bg-white'
                                            }
                                            `}
                                    >
                                        {category.name}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <button
                            onClick={() => {
                                if (emblaApi) {
                                    emblaApi.scrollNext()
                                }
                            }}
                        >
                            <ArrowForwardIos />
                        </button>
                    </div>
                </Container>
            </div>

            <Container size="lg">
                {(selectedCategories.length > 0 ||
                    searchPhrases.length > 0) && (
                    <Text type="subtitle" className="mt-8 mb-2">
                        Utdanninger innen
                    </Text>
                )}
                <div className="flex flex-wrap gap-2 mb-4">
                    {searchPhrases && (
                        <div className="px-4 py-1 border border-black rounded-3xl text-sm bg-sonans-new-primary">
                            <div onClick={onClick}>
                                <Text>{capitalize(searchPhrases)}</Text>
                            </div>
                        </div>
                    )}
                    {selectedCategories.map((category) => (
                        <button
                            key={category.id}
                            onClick={() => {
                                handleCategorySelection(category)
                            }}
                            className="px-4 py-1 border border-black rounded-3xl text-sm
            bg-sonans-new-green"
                        >
                            <Text>{category.name}</Text>
                        </button>
                    ))}
                </div>
            </Container>
        </div>
    )
}

export default CategoriesFiltration
