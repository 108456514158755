import { useEffect, useState } from 'react'
import StoredResultsItem from './StoredResultsItem'
import { TStoredResultsItem } from '@/types'
import { Text } from '@/components/UI/Text'
import { Button } from '@/components/UI/Button'
import { Input } from './Input'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '@/utils'

const StoredResultsList = () => {
    const [results, setResults] = useState<TStoredResultsItem[]>([])
    const [refCodeText, setRefCodeText] = useState('')
    const [errorText, setErrorText] = useState('')

    const handleDeleteItem = (idToDelete: string) => {
        const updatedResults = results.filter(
            (result) => result.id !== idToDelete
        )
        setResults(updatedResults)
    }

    useEffect(() => {
        const response = localStorage.getItem('lagredeKarakterer')
        if (response) {
            try {
                const parsedResponse = JSON.parse(response)
                setResults(parsedResponse)
            } catch (error) {
                console.error('Error parsing stored results:', error)
            }
        } else {
            console.log('No stored results found')
        }
    }, [])

    const navigate = useNavigate()

    async function updateRefCodeAndNavigate() {
        try {
            await axios.get(API_URL + 'calculations/' + refCodeText)
            navigate(`/karakterer/${refCodeText}`)
        } catch (error) {
            if (error instanceof Error) {
                setErrorText(
                    `Dine lagrede karakterer under referansekode ${refCodeText} eksisterer ikke i våre systemer. Vi sletter referansekoder som ikke har vært aktive eller benyttet på over 6 mnd.`
                )
            }
        }
    }

    return (
        <div className="border border-sonans-new-gray px-4 mx-2 py-4 xs:px-8 xs:py-4 rounded-2xl bg-sonans-gray-hue">
            <div className="mb-4">
                <Text type="title">Lagrede resultater</Text>
                <div className="flex gap-2 items-center">
                    <Input
                        noIcon={true}
                        className={'py-3 w-full'}
                        placeholder="Referansekode"
                        value={refCodeText}
                        onChange={(e) => setRefCodeText(e.target.value)}
                    />
                    <Button
                        className="karakterkalkulator_forsiden_referansekode_hentutregning w-48 md:text-base mb-0 h-[36px] md:h-[34px] xl:h-[40px]"
                        onClick={updateRefCodeAndNavigate}
                    >
                        Hent utregning
                    </Button>
                </div>
                {errorText && (
                    <div className="text-red-500 text-sm">{errorText}</div>
                )}
            </div>
            {results.length > 0 && (
                <div className="grid grid-cols-8 sm:grid-cols-4 border-b border-sonans-new-black font-bold text-[10px] xs:text-[12px] sm:text-[14px]">
                    <div className="col-span-3 sm:col-span-1 text-left">
                        <div className="hidden sm:flex items-center justify-start">
                            <Text>Referansekode</Text>
                        </div>
                        <div className="flex items-center justify-start sm:hidden">
                            <Text>Referanse</Text>
                        </div>
                    </div>
                    <div className="col-span-3 sm:col-span-1 text-left">
                        <div className="flex items-center justify-start">
                            <Text>Navn</Text>
                        </div>
                    </div>
                    <div className="col-span-1 sm:col-span-1 text-left">
                        <div className="flex items-center justify-start">
                            <Text>Snitt</Text>
                        </div>
                    </div>
                    <div className="col-span-1 sm:col-span-1 sm:px-4 ">
                        <div className="flex items-center justify-end">
                            <Text>Slett</Text>
                        </div>
                    </div>
                </div>
            )}
            {results.length > 0 ? (
                <div>
                    {results.map((result: TStoredResultsItem) => (
                        <StoredResultsItem
                            key={result.id}
                            id={result.id}
                            data={result.data}
                            onDelete={handleDeleteItem}
                        />
                    ))}
                </div>
            ) : (
                <Text className="pb-2">
                    Du kan velge å lagre utregningene dine. Du finner dem da
                    igjen her!
                </Text>
            )}
        </div>
    )
}

export default StoredResultsList
