import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch, IEducationalProgram } from '@/types'
import { useNavigate } from 'react-router-dom'
import { setSelectedEducationalProgram } from '@/reducers/educationalPrograms'
import { twJoin } from 'tailwind-merge'
import { fetchAdditionalCourses, fetchCourses } from '@/reducers/courses'
import { resetGrades } from '@/reducers/grades'
import { ExpandMore } from '@mui/icons-material'
import { Text } from '@/components/UI/Text'

interface Props {
    program: IEducationalProgram
}

export function EducationalProgramItem({ program }: Props) {
    const [areasIsVisible, setAreasIsVisible] = useState<boolean>(false)

    useEffect(() => {
        // Set default state for Musikk, dans og drama
        if (program.name === 'Musikk, dans og drama') {
            setAreasIsVisible(true)
        }
    }, [program.name])

    const dispatch = useDispatch<AppDispatch>()

    const navigate = useNavigate()

    return (
        <>
            <button
                className={twJoin('karakterkalkulator_utdanningsprogram_klikk',
                    'text-left p-6 font-medium flex justify-between items-center text-lg transition-all duration-75 animate-reveal hover:pl-7 hover:bg-slate-50 rounded-tl-xl last:rounded-b-xl last:border-b-0 text-[0.625rem] xs:text-[0.75rem] sm:text-[1rem] leading-4 sm:leading-5 md:leading-7',
                    areasIsVisible ? '' : 'border-b border-[rgba(0,0,0,0.5)]'
                )}
                onClick={() => {
                    if (program.programAreas.length > 0) {
                        setAreasIsVisible(!areasIsVisible)
                    } else {
                        dispatch(setSelectedEducationalProgram(program))
                        dispatch(fetchCourses())
                        dispatch(resetGrades())
                        dispatch(fetchAdditionalCourses())
                        navigate('/karakterer')
                    }
                }}
            >
                {program.name}
                {program.programAreas.length > 0 && (
                    <ExpandMore
                        className={twJoin(
                            'w-6',
                            areasIsVisible ? 'rotate-[180deg]' : '',
                            'transition-all duration-75'
                        )}
                    />
                )}
            </button>
            {areasIsVisible && (
                <div className="animate-reveal flex flex-col">
                    {program.programAreas.map((area) => (
                        <button
                            onClick={() => {
                                dispatch(setSelectedEducationalProgram(area))
                                dispatch(fetchCourses())
                                dispatch(resetGrades())
                                dispatch(fetchAdditionalCourses())
                                navigate('/karakterer')
                            }}
                            className={twJoin('karakterkalkulator_utdanningsprogram_klikk',
                                'py-6 px-8 text-lg text-left transition-all duration-75 hover:bg-slate-50 hover:pl-9 first:border-t border-b border-[rgba(0,0,0,0.5)] flex items-center text-[0.625rem] xs:text-[0.75rem] sm:text-[1rem] leading-4 sm:leading-5 md:leading-7'
                            )}
                            key={area.id}
                        >
                            {`${area.name}`}
                        </button>
                    ))}
                </div>
            )}
        </>
    )
}
