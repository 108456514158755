import { createPortal } from 'react-dom'
import { Overlay } from './Overlay'

interface Props {
    children: React.ReactNode
    onClick: () => void
}

export function Modal({ children, onClick }: Props) {
    return createPortal(
        <Overlay onClick={onClick}>{children}</Overlay>,
        document.getElementById('overlay') as HTMLElement
    )
}
