import { Container } from '@/components/UI'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState, IProgamType } from '@/types'
import { useNavigate, useParams } from 'react-router-dom'
import { setSelectedProgramType } from '@/reducers/programTypes'
import { setSelectedEducationalProgram } from '@/reducers/educationalPrograms'
import { resetGrades, setTwentyThreeFiveRule } from '@/reducers/grades'
import { fetchAdditionalCourses, fetchCourses } from '@/reducers/courses'
import { AppLayout } from '@/components/UI/AppLayout'
import StoredResultsList from '@/components/UI/StoredResultsList'
import { useEffect } from 'react'
import { setReferenceCode, setUserAlias } from '@/reducers/referenceCode'
import { Text } from '@/components/UI/Text'
import { twMerge } from 'tailwind-merge'

export function ProgramTypes() {
    const { data } = useSelector((state: AppState) => state.programTypes)

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { referenceCode } = useParams()

    function handleProgramTypeClick(programType: IProgamType) {
        // Push kalkulator start event to Google Tag Manger
        // dataLayer.push({
        //     event: 'karakterkalkulator_start',
        // })

        if (programType.name === '23/5-regelen') {
            dispatch(setSelectedProgramType(programType))
            dispatch(setSelectedEducationalProgram(null))
            dispatch(setTwentyThreeFiveRule(true))
            dispatch(resetGrades())
            dispatch(fetchCourses())
            dispatch(fetchAdditionalCourses())
            navigate('/karakterer')
        } else {
            dispatch(setSelectedProgramType(programType))
            dispatch(setSelectedEducationalProgram(null))
            dispatch(setTwentyThreeFiveRule(false))
            navigate('/utdanningsprogram')
        }
    }

    useEffect(() => {
        if (referenceCode) {
            navigate(`/karakterer/${referenceCode}`)
        }
        dispatch(setReferenceCode(''))
        dispatch(setUserAlias(''))
    }, [dispatch, navigate])

    return (
        <AppLayout>
            {/* Margin bottom can be removed if min height of 100vh is set for the applayout. It doesn't seem to work as intended */}
            <section className="animate-reveal mb-14">
                <Container className="flex flex-col gap-6">
                    <div className="my-2 sm:my-10 flex items-center justify-center">
                        <Text type="title" center>
                            Hva gikk du på videregående?
                        </Text>
                    </div>
                    <div className="flex flex-col md:flex-row gap-5 justify-center items-center mb-6 md:mb-20">
                        {data.map((programType) => (
                            <button
                                onClick={() =>
                                    handleProgramTypeClick(programType)
                                }
                                key={programType.id}
                                className={twMerge(
                                    'karakterkalkulator_start',
                                    'w-5/6 sm:w-96 rounded-full relative hover:bg-sonans-green-hue shadow-hard border border-black py-6 flex flex-col items-center justify-between active:shadow-[0px_0px_0px] active:translate-y-1 active:translate-x-1 duration-[50ms]',
                                    programType.name === '23/5-regelen'
                                        ? 'bg-sonans-new-pink'
                                        : 'bg-sonans-new-green'
                                )}
                            >
                                <p className="text-[14px] xs:text-[16px] sm:text-[18px]">
                                    {programType.name === '23/5-regelen' && (
                                        <p className="text-sm absolute top-2 left-0 w-full text-center">
                                            Jeg bruker
                                        </p>
                                    )}
                                    {programType.name}
                                </p>
                            </button>
                        ))}
                    </div>
                    <StoredResultsList />
                </Container>
            </section>
        </AppLayout>
    )
}
