import React, { ButtonHTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Close from '@mui/icons-material/Close'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode
    mode?: 'primary' | 'secondary' | 'pagination'
    size?: 'sm' | 'default' | 'none'
    icon?: 'left' | 'up' | 'right' | 'down' | 'close' | 'none'
    smallShadow?: boolean
    navigateTo?: string
    className?: string
}

function getIconComponent(icon?: ButtonProps['icon']) {
    switch (icon) {
        case 'left':
            return <ArrowBackIcon fontSize="inherit" />
        case 'up':
            return <ArrowUpwardIcon fontSize="inherit" />
        case 'right':
            return <ArrowForwardIcon fontSize="inherit" />
        case 'down':
            return <ArrowDownwardIcon fontSize="inherit" />
        case 'close':
            return <Close fontSize="inherit" />
        default:
            return null
    }
}

export function Button({
    children,
    mode = 'primary',
    size = 'default',
    icon = 'none',
    navigateTo = '',
    smallShadow = false,
    className = '',
    ...props
}: ButtonProps) {
    const modeClasses = {
        primary:
            'bg-sonans-new-primary rounded-full flex justify-center items-center border border-black hover:bg-sonans-primary active:shadow-[0px_0px_0px] active:translate-y-1 active:translate-x-1 mb-2 duration-[50ms]',
        secondary:
            'border bg-white border-black rounded-full flex justify-center items-center active:shadow-[0px_0px_0px] active:translate-y-1 active:translate-x-1 mb-2 duration-[50ms]',
        pagination:
            'bg-sonans-new-primary aspect-square rounded-full w-10 h-10 border border-black hover:bg-sonans-primary active:shadow-[0px_0px_0px] active:translate-y-1 active:translate-x-1 mb-2 duration-[50ms] active:bg-sonans-new-primary',
    }

    const sizeClasses = {
        sm: 'py-1 px-2 xs:px-4 xs:py-2 text-[10px] xs:text-md md:text-lg',
        default: 'py-1 px-2 xs:px-4 xs:py-3 text-[10px] xs:text-md md:text-lg',
        none: '',
    }

    const IconComponent = getIconComponent(icon)

    const iconPositionClasses =
        icon !== 'none'
            ? icon === 'left'
                ? 'flex justify-start items-center gap-1'
                : 'flex justify-end items-center gap-1'
            : 'flex justify-center items-center'

    return (
        <>
            {navigateTo ? (
                <Link to={navigateTo}>
                    <button
                        {...props}
                        className={twMerge(
                            modeClasses[mode],
                            sizeClasses[size],
                            smallShadow ? 'shadow-hard-sm' : 'shadow-hard',
                            iconPositionClasses,
                            className
                        )}
                    >
                        {icon === 'left' && IconComponent} {children}{' '}
                        {icon !== 'left' && icon !== 'none' && IconComponent}
                    </button>
                </Link>
            ) : (
                <button
                    {...props}
                    className={twMerge(
                        modeClasses[mode],
                        sizeClasses[size],
                        smallShadow ? 'shadow-hard-sm' : 'shadow-hard',
                        iconPositionClasses,
                        className,
                        props.disabled ? "opacity-50 cursor-not-allowed" : ""
                    )}
                >
                    {icon === 'left' && IconComponent} {children}{' '}
                    {icon !== 'left' && icon !== 'none' && IconComponent}
                </button>
            )}
        </>
    )
}
