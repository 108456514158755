import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDispatch, useSelector } from 'react-redux'
import { CourseItem } from './CourseItem'
import { removeCourse, sortCourses } from '@/reducers/courses'
import { hasNatrualScience } from '@/utils/helpers'
import { AppDispatch, AppState, ICourse } from '@/types'
import { Text } from '@/components/UI'
import { removeGrade } from '@/reducers/grades'

type Props = {
    courses: ICourse[]
}

/**
 * Component for displaying list of courses
 */
export function CourseList({ courses }: Props) {
    const twentythreeFiveRule = useSelector((state: AppState) => state.grades.twentythreeFiveRule);
    const grades = useSelector((state: AppState) => state.grades.grades);

    const dispatch = useDispatch<AppDispatch>()

    const moveGrade = (dragIndex: number, hoverIndex: number) => {
        dispatch(sortCourses(dragIndex, hoverIndex))
    }


    return (
        <ul className="w-full flex flex-col animate-reveal">
            <li className="flex justify-between pb-1 px-1 text-lg border-b pt-4 sticky bg-sonans-new-light-gray top-[96px] sm:top-[128px] md:top-[103px] 3xl:top-[127px] z-30 border-sonans-new-black font-bold">
                <Text>Fag</Text>
                <div className="flex justify-end">
                    <Text className="mr-2 md:mr-[3.5rem]">Karakter</Text>
                    <Text>Slett</Text>
                </div>
            </li>

            <DndProvider backend={HTML5Backend}>
                {courses.map((course, index) => (
                    <CourseItem
                        moveGrade={moveGrade}
                        id={course.id}
                        index={index}
                        key={course.id}
                        disabled={
                            (twentythreeFiveRule &&
                                !course.twentythreeFiveRule &&
                                !course.sciencePoints) ||
                            hasNatrualScience(course, grades)
                        }
                        onRemoveCourse={() => {
                            dispatch(removeCourse(course))
                            dispatch(removeGrade(course))
                        }}
                        course={course}
                    />
                ))}
            </DndProvider>
        </ul>
    )
}
