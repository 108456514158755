import React from 'react'
import HigherEducationPathItem from './HigherEducationPathItem'
import { Text } from '../Text'
import { Favorite } from '@mui/icons-material'
import { StudyItem } from '@/types'
import { helperTextORDF, helperTextORD } from '@/helpers/HelperTexts'

export type HigherEducationPathListProps = {
    studyFields: StudyItem[]
}

const HigherEducationPathList: React.FC<HigherEducationPathListProps> = ({
    studyFields,
}) => {
    return (
        <div>
            <div className="border-t border-b md:border border-black md:rounded-2xl">
                <div>
                    <div className="grid items-center gap-2 w-full grid-cols-[24px,3fr,1fr,1fr,24px] md:grid-cols-[0.5fr,3fr,1fr,1fr,1fr,1fr] p-3 md:p-4">
                        <Favorite className="invisible" />
                        <Text className="flex w-full md:hidden">
                            Utdanning og sted
                        </Text>
                        <Text className="hidden md:flex">Utdanning</Text>
                        <Text className="hidden md:flex">Sted</Text>
                        <Text
                            popupTitle={helperTextORD.title}
                            popupContent={helperTextORD.content}
                        >
                            <span className="bg-sonans-new-green leading-[0.7rem] md:leading-4">
                                ORD
                            </span>
                        </Text>
                        <Text
                            className="flex"
                            popupTitle={helperTextORDF.title}
                            popupContent={helperTextORDF.content}
                        >
                            <span className="bg-sonans-new-pink leading-[0.7rem] md:leading-4">
                                ORDF
                            </span>
                        </Text>
                        <div></div>
                    </div>

                    {studyFields.map((studyItem) => (
                        <HigherEducationPathItem
                            key={studyItem.id}
                            studyItem={studyItem}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HigherEducationPathList
