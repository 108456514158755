import { twJoin } from 'tailwind-merge'

interface Props {
    children: React.ReactNode
    className?: string
    size?: 'default' | 'lg'
}

export function Container({
    children,
    className = '',
    size = 'default',
}: Props) {
    return (
        <div
            className={twJoin(
                `${size === 'default' && 'mx-auto w-full xs:max-w-xs sm:max-w-md md:max-w-lg'}`,
                `${size === 'lg' && 'mx-auto w-full xs:max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl'}`,
                className
            )}
        >
            {children}
        </div>
    )
}
