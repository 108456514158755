import { toFixedDecimal } from '@/reducers/helpers'
import React from 'react'
import { TStoredResultsItem } from '@/types'
import { Link } from 'react-router-dom'
import Close from '@mui/icons-material/Close'

const StoredResultsItem: React.FC<TStoredResultsItem> = ({
    id,
    data: { userAlias, results, lastUpdate },
    onDelete,
}) => {
    const formatDate = (lastUpdate: number) => {
        const date = new Date(lastUpdate)
        const day = String(date.getDate()).padStart(2, '0') // Add leading zero if necessary
        const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
        const year = date.getFullYear()
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        return `${day}.${month}.${year} ${hours}:${minutes}`
    }

    const deleteItemFromLocalStorage = (id: string) => {
        const lsData: string | null = localStorage.getItem('lagredeKarakterer')
        if (lsData) {
            let parsedLsData = []
            try {
                parsedLsData = JSON.parse(lsData)
                if (!Array.isArray(parsedLsData)) {
                    throw new Error('Data in localStorage is not an array')
                }
            } catch (error) {
                console.error('Error parsing stored data:', error)
                parsedLsData = []
            }

            const updatedData = parsedLsData.filter(
                (grades: any) => grades.id !== id
            )
            localStorage.setItem(
                'lagredeKarakterer',
                JSON.stringify(updatedData)
            )
        }
    }

    return (
        <div className="grid text-[10px] xs:text-[12px] sm:text-[14px] py-3 last:border-b-0 last:pb-0 grid-cols-8 sm:grid-cols-4 border-b">
            <Link to={`/karakterer/${id}`} className="karakterkalkulator_forsiden_referansekode_resultatklikk col-span-3 sm:col-span-1 mr-1">
                <div className="h-full flex flex-col justify-center items-start">
                    <div>{id}</div>
                    <div className="text-[8px] sm:text-[10px]">{formatDate(lastUpdate)}</div>
                </div>
            </Link>
            <Link to={`/karakterer/${id}`} className="karakterkalkulator_forsiden_referansekode_resultatklikk col-span-3 sm:col-span-1 mr-1">
                <div className="h-full flex items-center justify-start">
                    {userAlias && userAlias}
                </div>
            </Link>
            <Link
                to={`/karakterer/${id}`}
                key={id}
                className="karakterkalkulator_forsiden_referansekode_resultatklikk col-span md:col-span-1"
            >
                <div className="h-full flex items-center justify-start mr-1">
                    {toFixedDecimal(results)}
                </div>
            </Link>
            <div className="col-span-1 sm:col-span-1">
                <button
                    onClick={(event) => {
                        event.preventDefault()
                        deleteItemFromLocalStorage(id)
                        onDelete && onDelete(id)
                    }}
                    className="h-full flex items-center px-4 py-2 w-full text-[14px] justify-center sm:justify-end"
                >
                    <Close fontSize="inherit" />
                </button>
            </div>
        </div>
    )
}

export default StoredResultsItem
