import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState, AppDispatch, IPoints } from '@/types'
import {
    averageGrades,
    createSciencePointsGroups,
    filterGrades,
    sum,
    _calculateAgePoints,
    _calculateSciencePoints,
} from './helpers'

export interface resultsState {
    additionalPoints: number
    sciencePoints: number
    betterThenRegular: boolean
    average: IPoints
    agePoints: IPoints
    gradePoints: IPoints
    competitionPoints: IPoints
}

const initialState: resultsState = {
    additionalPoints: 0,
    sciencePoints: 0,
    betterThenRegular: false,
    average: {
        regular: 0,
        twentythreeFiveRule: 0,
        twentythreeFiveRuleExcludeScience: 0,
    },
    agePoints: {
        regular: 0,
        twentythreeFiveRule: 0,
        twentythreeFiveRuleExcludeScience: 0,
    },
    gradePoints: {
        regular: 0,
        twentythreeFiveRule: 0,
        twentythreeFiveRuleExcludeScience: 0,
    },
    competitionPoints: {
        regular: 0,
        twentythreeFiveRule: 0,
        twentythreeFiveRuleExcludeScience: 0,
    },
}

function checkPointDiff(argv1: number, argv2: number) {
    return argv1 > argv2
}

export const resultsSlice = createSlice({
    name: 'results',
    initialState,
    reducers: {
        setBetterThenRegular(state) {
            state.betterThenRegular = ((checkPointDiff(state.gradePoints.twentythreeFiveRule, state.gradePoints.regular) || checkPointDiff(state.gradePoints.twentythreeFiveRule, state.gradePoints.regular)) || state.gradePoints.regular < (state.gradePoints.regular + state.agePoints.regular))
        },
        setAdditionalPoints(state, action: PayloadAction<number>) {
            state.additionalPoints = action.payload
        },
        setAverage(state, action: PayloadAction<IPoints>) {
            state.average = action.payload
        },
        setAgePoints(state, action: PayloadAction<IPoints>) {
            state.agePoints = action.payload
        },
        setSciencePoints(state, action: PayloadAction<number>) {
            state.sciencePoints = action.payload
        },
        setCompetitionPoints(state, action: PayloadAction<number>) {
            state.competitionPoints.regular = action.payload
        },
        calculateGradePoints(state) {
            state.gradePoints = {
                regular: state.average.regular * 10,
                twentythreeFiveRule: state.average.twentythreeFiveRule * 10,
                twentythreeFiveRuleExcludeScience:
                    state.average.twentythreeFiveRuleExcludeScience * 10,
            }
        },
        calculateCompetitionPoints(state) {
            const { gradePoints, agePoints, sciencePoints, additionalPoints } =
                state

            state.competitionPoints = {
                regular: sum(
                    gradePoints.regular,
                    agePoints.regular,
                    sciencePoints,
                    additionalPoints
                ),
                twentythreeFiveRule: sum(
                    gradePoints.twentythreeFiveRule,
                    agePoints.twentythreeFiveRule,
                    sciencePoints,
                    additionalPoints
                ),
                twentythreeFiveRuleExcludeScience: sum(
                    gradePoints.twentythreeFiveRuleExcludeScience,
                    agePoints.twentythreeFiveRule,
                    additionalPoints
                ),
            }
        }
    },
})

export const {
    setAverage,
    setAdditionalPoints,
    setBetterThenRegular,
    setSciencePoints,
    setAgePoints,
    calculateGradePoints,
    calculateCompetitionPoints,
    setCompetitionPoints
} = resultsSlice.actions

export const calculateAverage = () => {
    return function (dispatch: AppDispatch, getState: () => AppState) {
        const { grades } = getState().grades


        if (grades.length === 0) {
            dispatch(
                setAverage({
                    regular: 0,
                    twentythreeFiveRule: 0,
                    twentythreeFiveRuleExcludeScience: 0,
                })
            )
            return
        }

        dispatch(
            setAverage({
                regular: averageGrades(filterGrades(grades)),
                twentythreeFiveRule: averageGrades(
                    filterGrades(grades, '23/5')
                ),
                twentythreeFiveRuleExcludeScience: averageGrades(
                    filterGrades(grades, 'exclude science')
                ),
            })
        )
    }
}

export function calculateAdditionalPoints() {
    return function (dispatch: AppDispatch, getState: () => AppState) {
        const { experiences } = getState().experiences

        if (experiences.length === 0) {
            dispatch(setAdditionalPoints(0))
            return
        }

        if (experiences.includes('Ingen av disse')) {
            dispatch(setAdditionalPoints(0))
            return
        }

        const points = experiences
            .map((experience) => {
                if (experience === 'Minimum 30 studiepoeng') return 1
                return 2
            })
            .reduce((acc, cur) => (acc += cur), 0)

        if (points > 2) {
            dispatch(setAdditionalPoints(2))
        } else {
            dispatch(setAdditionalPoints(points))
        }
    }
}

export function calculateSciencePoints() {
    return function (dispatch: AppDispatch, getState: () => AppState) {
        const { grades } = getState().grades

        const gradesWithSciencePoints = grades.filter(
            (grade) => grade.course.sciencePoints && grade.grades.some((g) => g.grade > 0)
        )

        if (gradesWithSciencePoints.length === 0) {
            dispatch(setSciencePoints(0))
        }

        const groups = createSciencePointsGroups(gradesWithSciencePoints)

        const sciencePoints = _calculateSciencePoints(groups)

        if (sciencePoints >= 4) {
            dispatch(setSciencePoints(4))
        } else {
            dispatch(setSciencePoints(sciencePoints))
        }
    }
}

export function calculateAgePoints() {
    return function (dispatch: AppDispatch, getState: () => AppState) {
        const { birthYear } = getState().experiences

        dispatch(
            setAgePoints({
                regular: _calculateAgePoints(birthYear, 'regular'),
                twentythreeFiveRule: _calculateAgePoints(
                    birthYear,
                    'twentythreeFiveRule'
                ),
                twentythreeFiveRuleExcludeScience: _calculateAgePoints(
                    birthYear,
                    'twentythreeFiveRule'
                ),
            })
        )
    }
}

export function handleCalculations() {
    return function (dispatch: AppDispatch) {
        dispatch(calculateAgePoints())
        dispatch(calculateAdditionalPoints())
        dispatch(calculateAverage())
        dispatch(calculateSciencePoints())
        dispatch(calculateGradePoints())
        dispatch(calculateCompetitionPoints())
    }
}
