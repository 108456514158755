import {
    IEducationalProgram,
    IProgramArea,
    ICourse,
    ICourseGrade,
} from '@/types'
import { CURRENT_YEAR } from '@/utils/constants'

/**
 * Helper function that generates a query string for the course page to fetch correct courses from the API
 */
export function generateCourseParams(
    selectedEducationalProgram: IEducationalProgram | IProgramArea
): string {
    let params = ''

    if ((selectedEducationalProgram as IProgramArea).educationalProgramId) {
        params = `programId=${(selectedEducationalProgram as IProgramArea).educationalProgramId}&areaId=${
            (selectedEducationalProgram as IProgramArea).id
        }`
    } else {
        params = `programId=${selectedEducationalProgram?.id}`
    }

    return params
}

/**
 * Helper function that returns a fixed decimal number to display on the result page
 */
export function toFixedDecimal(number: number) {
    return Math.floor(number * 10) / 10
}

/**
 * Filter array of grades to exclude grades with 0
 */
function filterGradesRegular(grades: ICourseGrade[]): number[] {
    const filteredGrades = filterNatrualScience(grades)

    const output: number[] = []

    // return empty array if no grades
    if (!filteredGrades || filteredGrades.length === 0) return output

    filteredGrades.forEach((gradeItem) => {
        if (gradeItem.grades) {
            gradeItem.grades.forEach((grade) => {
                if (grade.grade > 0) {
                    output.push(grade.grade)
                }
            })
        }
    })

    return output
}

/**
 * Filter grades included in 23/5 rule and exclude natrual science courses
 */
function filterGradesWithTwentyThreeFive(grades: ICourseGrade[]): number[] {
    const filteredGrades = filterNatrualScience(grades)

    const output: number[] = []

    // return empty array if no grades
    if (!filteredGrades || filteredGrades.length === 0) return output

    filteredGrades.forEach((gradeItem) => {
        if (gradeItem.grades) {
            gradeItem.grades.forEach((grade) => {
                if (
                    (gradeItem.course.twentythreeFiveRule ||
                        gradeItem.course.sciencePoints > 0) &&
                    grade.grade > 0
                ) {
                    output.push(grade.grade)
                }
            })
        }
    })

    return output
}

/**
 * exclude other natrual science courses if user has natrual science vg1 studieforberedende
 */
function filterNatrualScience(grades: ICourseGrade[]): ICourseGrade[] {
    return grades.filter((grade) => {
        if (!grade.course.code.includes('NAT')) return true
        if (
            grades
                .map((grade) => grade.course.name)
                .join(' ')
                .includes('studieforberedende')
        ) {
            return grade.course.name.includes('studieforberedende')
        } else {
            return true
        }
    })
}

/**
 * Filter grades included in 23/5 rule and exclude natrual science courses
 */
function filterGradesWithTwentyThreeFiveExcludeScience(
    grades: ICourseGrade[]
): number[] {
    const filteredGrades = filterNatrualScience(grades)

    const output: number[] = []

    // return empty array if no grades
    if (!filteredGrades || filteredGrades.length === 0) return output

    filteredGrades.forEach((gradeItem) => {
        if (gradeItem.grades) {
            gradeItem.grades.forEach((grade) => {
                if (
                    gradeItem.course.twentythreeFiveRule &&
                    !(gradeItem.course.sciencePoints > 0) &&
                    grade.grade > 0
                ) {
                    output.push(grade.grade)
                }
            })
        }
    })

    return output
}

/**
 * Sum array of numbers
 */
export function sum(...values: number[]): number {
    return values.reduce((acc, cur) => (acc += cur), 0)
}

/**
 * Filter grades parentFunction
 */
export function filterGrades(
    grades: ICourseGrade[],
    mode: 'default' | '23/5' | 'exclude science' = 'default'
): number[] {
    if (mode === '23/5') {
        return filterGradesWithTwentyThreeFive(grades)
    }

    if (mode === 'exclude science') {
        return filterGradesWithTwentyThreeFiveExcludeScience(grades)
    }

    return filterGradesRegular(grades)
}

/**
 * Helper function that calculates the average of an array of grades
 */
export function averageGrades(grades: number[]): number {
    if (grades.length === 0) return 0
    return grades.reduce((acc, cur) => (acc += cur), 0) / grades.length
}

/**
 * Helper function that calculates age points based on array of ages
 */
export function _calculateAgePoints(
    birthYear: string,
    mode: 'regular' | 'twentythreeFiveRule'
): number {
    const ages = {
        regular: [20, 21, 22, 23],
        twentythreeFiveRule: [24, 25, 26, 27],
    }

    let year = CURRENT_YEAR

    const currentDate = new Date().getMonth() + 1

    if (currentDate >= 8) {
        year = CURRENT_YEAR + 1
    }

    switch (true) {
        case birthYear.length < 4:
            return 0
        case +birthYear === year - ages[mode][0]:
            return 2
        case +birthYear === year - ages[mode][1]:
            return 4
        case +birthYear === year - ages[mode][2]:
            return 6
        case +birthYear <= year - ages[mode][3]:
            return 8
        default:
            return 0
    }
}

type ScienceGroup = {
    [key: string]: ICourse[]
}

/**
 * Helper function that creates groups of science points
 */
export function createSciencePointsGroups(
    grades: ICourseGrade[]
): ScienceGroup {
    const groups: ScienceGroup = {}

    // initialize group objects
    grades.forEach((grade) => {
        if (grade.course.sciencePointsGroup) {
            groups[grade.course.sciencePointsGroup] = []
        }
    })

    // add courses to group objects
    grades.forEach((grade) => {
        if (grade.course.sciencePointsGroup) {
            groups[grade.course.sciencePointsGroup].push(grade.course)
        }
    })

    return groups
}

/**
 * Helper function that calculates science points
 */
export const _calculateSciencePoints = (groups: {
    [key: string]: ICourse[]
}): number => {
    const points: number[] = []

    Object.values(groups).forEach((group) => {
        let groupPoints = group
            .map((course) => course.sciencePoints)
            .reduce((acc, cur) => (acc += cur), 0)
        if (groupPoints >= 1.5) {
            points.push(1.5)
        } else {
            points.push(groupPoints)
        }
    })
    return points.reduce((acc, cur) => (acc += cur), 0)
}
