import { Header } from './Header'
import { ScrollToTop } from './ScrollToTop'
import { Footer } from './Footer'
import ActionFeedback from './ActionFeedback'
import React from 'react'

interface Props {
    children: React.ReactNode
}

export function AppLayout({ children }: Props) {
    return (
        <main className="font-sonans min-h-[100vh] flex flex-col">
            <Header />
            <ScrollToTop />
            <ActionFeedback />
            <div className="pt-8">{children}</div>
            <Footer />
        </main>
    )
}
