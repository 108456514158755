import { AppState } from '@/types'
import { API, API_URL } from '@/utils'
import axios from 'axios'
import { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from './Text'
import { Input } from './Input'
import { Button } from './Button'
import { useNavigate, useParams } from 'react-router-dom'
import {
    setActionFeedbackMessage,
    setActionFeedbackType,
    setIsVisible,
} from '@/reducers/actionFeedback'
import { setReferenceCode, setUserAlias } from '@/reducers/referenceCode'
import { helperTextSaveCalculations } from '@/helpers/HelperTexts'
import { ChevronLeft } from '@mui/icons-material'
import { twJoin } from 'tailwind-merge'

interface FormValues {
    gradeName?: string
    email?: string
}

type SaveResultsProps = {
    className?: string
    container?: 'none' | 'default' | 'lg'
}

const SaveResults: React.FC<SaveResultsProps> = ({
    className = '',
    container = 'none',
}) => {
    const [email, setEmail] = useState('')

    const [formValues, setFormValues] = useState<FormValues>({
        gradeName: '',
        email: '',
    })
    const [emailTime, setEmailTime] = useState<boolean>(false)
    const [saveButtonText, setSaveButtonText] = useState<string>('Lagre')
    const [newSaveButtonText, setNewSaveButtonText] = useState<string>(
        'Lagre nytt resultat'
    )

    const [saveAsNew, setSaveAsNew] = useState<boolean>(false)

    const [emailError, setEmailError] = useState<string | null>(null)
    const currentYear = new Date(Date.now()).getFullYear()

    const competitionPoints = useSelector(
        (state: AppState) => state.results.competitionPoints
    )
    const twentythreeFiveRule = useSelector(
        (state: AppState) => state.grades.twentythreeFiveRule
    )
    const { referenceCode: code } = useParams<{ referenceCode: string }>()

    const userAlias = useSelector(
        (state: AppState) => state.referenceCode.userAlias
    )

    const grades = useSelector((state: AppState) => state.grades)
    const experiences = useSelector((state: AppState) => state.experiences)
    const educationalPrograms = useSelector(
        (state: AppState) => state.educationalPrograms
    )
    const programTypes = useSelector((state: AppState) => state.programTypes)
    const courses = useSelector((state: AppState) => state.courses)
    const favoriteStudyFields = useSelector(
        (state: AppState) => state.favoriteStudyFields
    )

    const placeholderDefaultCardName = 'Eks. Mine karakterer før Sonans'
    const defaultCardName = 'Min utregning'

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const gradeResults = twentythreeFiveRule
        ? competitionPoints.twentythreeFiveRule
        : competitionPoints.regular

    const getIdAndStoreData = async (requestType: string) => {
        const lsDataItemKarakterkalkulator =
            localStorage.getItem('karakterkalkulator')

        if (lsDataItemKarakterkalkulator) {
            const data = JSON.parse(lsDataItemKarakterkalkulator)

            let email
            if (formValues.email?.trim() !== '') {
                email = formValues.email
            } else {
                email = ''
            }

            if (formValues.gradeName === '') {
                if (userAlias) {
                    data.userAlias = userAlias
                } else {
                    data.userAlias = ''
                }
            } else {
                data.userAlias = formValues.gradeName
            }
            data.results = gradeResults

            let response

            if (requestType === 'POST') {
                response = await axios.post(
                    `${API('calculations')}`,
                    { data, email },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                checkIfIdExistsInLocalStorageAndStore(response.data)
                return response.data.id
            } else if (requestType === 'PUT') {
                if (email === '') {
                    response = await axios.put(
                        `${API('calculations')}/${code}`,
                        { data },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    checkIfIdExistsInLocalStorageAndStore(response.data)
                    return response.data.id
                } else {
                    response = await axios.put(
                        `${API('calculations')}/${code}`,
                        { data, email },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    checkIfIdExistsInLocalStorageAndStore(response.data)
                    return response.data.id
                }
            } else {
                throw new Error('Wrong http request')
            }
        }
    }

    const checkIfIdExistsInLocalStorageAndStore = (data: any) => {
        const lagredeKarakterer: string = 'lagredeKarakterer'
        const lsData: any = localStorage.getItem(lagredeKarakterer)
        let parsedLsData: any[] = Array.isArray(lsData) ? lsData : [lsData]

        if (lsData) {
            parsedLsData = JSON.parse(lsData)
        } else {
            parsedLsData = []
        }

        const idExists = parsedLsData.some(
            (grades: any) => grades.id === data.id
        )

        if (parsedLsData.length === 0 || !idExists) {
            parsedLsData.push(data)
            localStorage.setItem(
                lagredeKarakterer,
                JSON.stringify(parsedLsData)
            )
        } else {
            const updatedData = parsedLsData.map((grades: any) =>
                grades.id === data.id ? { ...grades, ...data } : grades
            )
            localStorage.setItem(lagredeKarakterer, JSON.stringify(updatedData))
        }
    }

    const handleChange = (
        event: ChangeEvent<HTMLInputElement>,
        fieldName: keyof FormValues
    ) => {
        const { value } = event.target
        setFormValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }))

        if (fieldName === 'email') {
            if (value.trim() === '') {
                setEmailError(null)
            } else {
                validateEmail(value)
            }
        }
    }

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const isValid = emailRegex.test(email)
        setEmailError(
            isValid
                ? null
                : 'Oppgi en gyldig e-postadresse for å få karakterutregningen på e-post.'
        )
        if (isValid) {
            setSaveButtonText('Lagre og send på e-post')
            setNewSaveButtonText('Lagre og send på e-post')
        } else {
            setSaveButtonText('Lagre')
            setNewSaveButtonText('Lagre nytt resultat')
        }
    }

    const updateLocalStorage = () => {
        const lastUpdate = new Date().getTime()

        localStorage.setItem(
            'karakterkalkulator',
            JSON.stringify({
                lastUpdate,
                grades,
                experiences,
                courses,
                educationalPrograms,
                programTypes,
                favoriteStudyFields,
            })
        )
    }

    const saveAndUpdateParams = async (requestType: string) => {
        try {
            const id = await getIdAndStoreData(requestType)
            setFormValues({
                gradeName: '',
                email: '',
            })
            setSaveAsNew(false)

            if (requestType === 'POST') {
                dispatch(
                    setActionFeedbackMessage(
                        `Resultatet ble lagret med id: ${id}`
                    )
                )
                dispatch(setActionFeedbackType('success'))
                dispatch(setIsVisible(true))
                updateLocalStorage()
                window.location.replace(`/karakterer/${id}`)
            } else if (requestType === 'PUT') {
                setReferenceCode(id)
                if (formValues.gradeName) {
                    setUserAlias(formValues.gradeName)
                }
                dispatch(setActionFeedbackMessage(`Resultatet ble oppdatert.`))
                dispatch(setActionFeedbackType('success'))
                dispatch(setIsVisible(true))
            }
        } catch (error) {
            if (requestType === 'POST') {
                dispatch(
                    setActionFeedbackMessage(
                        'Dine data ble ikke lagret. Prøv igjen senere.'
                    )
                )
                dispatch(setActionFeedbackType('error'))
                dispatch(setIsVisible(true))
            } else if (requestType === 'PUT') {
                dispatch(
                    setActionFeedbackMessage(
                        'Dine data ble ikke oppdatert. Prøv igjen senere.'
                    )
                )
                dispatch(setActionFeedbackType('error'))
                dispatch(setIsVisible(true))
            }
        }
    }

    return (
        <div className={className}>
            <div
                className={`py-8 ${container === 'lg' && 'mx-auto w-full xs:max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl'} ${container === 'default' && 'mx-auto w-full xs:max-w-xs sm:max-w-md md:max-w-lg'}`}
            >
                <Text
                    type="subtitle"
                    className="pb-4"
                    popupTitle={helperTextSaveCalculations.title}
                    popupContent={helperTextSaveCalculations.content}
                >
                    {code
                        ? `Lagre eller oppdater ditt resultat`
                        : 'Lagre din utregning'}
                </Text>

                {code ? (
                    <div className="rounded overflow-hidden shadow-lg bg-white max-w-full">
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">{code}</div>
                            <p className="text-gray-700 text-base">
                                Lagret med navn{' '}
                                <b>{userAlias ? userAlias : defaultCardName}</b>
                            </p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <p style={{ color: 'red' }}>
                            Du har ikke lagret dette resultatet enda.
                        </p>
                    </div>
                )}

                <div>
                    <div className="mt-4">
                        <div className="flex flex-col gap-2">
                            {!code && (
                                <div className="flex flex-col gap-1 mb-2">
                                    {userAlias ? (
                                        <Input
                                            type="text"
                                            noIcon
                                            value={formValues.gradeName}
                                            onChange={(e) =>
                                                handleChange(e, 'gradeName')
                                            }
                                            placeholder={userAlias}
                                        />
                                    ) : (
                                        <Input
                                            type="text"
                                            noIcon
                                            value={formValues.gradeName}
                                            onChange={(e) =>
                                                handleChange(e, 'gradeName')
                                            }
                                            placeholder={
                                                placeholderDefaultCardName
                                            }
                                        />
                                    )}
                                </div>
                            )}
                            <Button
                                className={twJoin(
                                    'w-full max-w-full items-center justify-center',
                                    code
                                        ? 'karakterkalkulator_karakterer_resultatoppdatere'
                                        : 'karakterkalkulator_karakterer_resultatlagre'
                                )}
                                size="sm"
                                onClick={() => {
                                    saveAndUpdateParams(code ? 'PUT' : 'POST')
                                }}
                            >
                                {code ? 'Oppdater resultat' : 'Lagre'}
                            </Button>
                        </div>

                        {code && (
                            <div className={twJoin(saveAsNew ? 'mb-4' : '')}>
                                <Button
                                    className={`karakterkalkulator_karakterer_resultatlagrenytt w-full max-w-full ${code ? '' : 'bg-gray-200 text-black-300 cursor-not-allowed opacity-50'}`}
                                    size="sm"
                                    disabled={code ? false : true}
                                    onClick={async () => {
                                        setSaveAsNew(!saveAsNew)
                                    }}
                                >
                                    {code ? 'Lagre som ny kopi' : ''}
                                    <ChevronLeft
                                        className="rotate-[-90deg]"
                                        fontSize="inherit"
                                    />
                                </Button>
                            </div>
                        )}
                        {saveAsNew && (
                            <div className={'flex items-center gap-2 mb-4'}>
                                {userAlias ? (
                                    <Input
                                        className="w-full"
                                        type="text"
                                        noIcon
                                        value={formValues.gradeName}
                                        onChange={(e) =>
                                            handleChange(e, 'gradeName')
                                        }
                                        placeholder={userAlias}
                                    />
                                ) : (
                                    <Input
                                        type="text"
                                        noIcon
                                        value={formValues.gradeName}
                                        onChange={(e) =>
                                            handleChange(e, 'gradeName')
                                        }
                                        placeholder={placeholderDefaultCardName}
                                    />
                                )}
                                <Button
                                    className={
                                        'self-start shrink-0 py-1 md:h-[34px] xl:h-[40px] mb-0'
                                    }
                                    size="sm"
                                    onClick={async () => {
                                        await saveAndUpdateParams('POST')
                                    }}
                                >
                                    Lagre
                                </Button>
                            </div>
                        )}
                        <div className={twJoin(emailTime ? 'mb-4' : '')}>
                            <Button
                                className={
                                    'karakterkalkulator_karakterer_resultatpaaepost w-full max-w-full flex items-center justify-center'
                                }
                                size="sm"
                                onClick={async () => {
                                    setEmailTime(!emailTime)
                                }}
                            >
                                Send på e-post
                                <ChevronLeft
                                    className="rotate-[-90deg]"
                                    fontSize="inherit"
                                />
                            </Button>
                        </div>
                        {emailTime && (
                            <div>
                                <div className={'flex items-center gap-1'}>
                                    <Input
                                        className="w-full"
                                        noIcon
                                        placeholder="E-post"
                                        type="email"
                                        value={formValues.email}
                                        onChange={(e) =>
                                            handleChange(e, 'email')
                                        }
                                    />
                                    <Button
                                        disabled={
                                            !!emailError || !formValues.email
                                        }
                                        className={
                                            'self-start shrink-0 py-1 md:h-[34px] xl:h-[40px] mb-0'
                                        }
                                        size="sm"
                                        onClick={async () => {
                                            try {
                                                let refId = ''
                                                let newSave = false
                                                if (code) {
                                                    refId = code
                                                } else {
                                                    const data =
                                                        await getIdAndStoreData(
                                                            'POST'
                                                        )
                                                    refId = data
                                                    newSave = true
                                                }
                                                if (emailError) return
                                                await axios.post(
                                                    API_URL + 'calculations/send_to_email',
                                                    {
                                                        email: formValues.email,
                                                        ref_id: refId,
                                                    },
                                                    {
                                                        headers: {
                                                            'Content-Type':
                                                                'application/json',
                                                        },
                                                    }
                                                )

                                                dispatch(
                                                    setActionFeedbackMessage(
                                                        `Resultatet ble lagret med id: ${refId} og sendt til ${formValues.email}`
                                                    )
                                                )
                                                dispatch(
                                                    setActionFeedbackType(
                                                        'success'
                                                    )
                                                )
                                                dispatch(setIsVisible(true))
                                                setEmailTime(false)
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    email: '',
                                                }))

                                                if (newSave) {
                                                    navigate(
                                                        `/karakterer/${refId}`,
                                                        {
                                                            replace: true,
                                                        }
                                                    )
                                                }

                                                // updateLocalStorage()
                                            } catch (error) {
                                                if (error instanceof Error) {
                                                    console.log(error)
                                                }
                                            }
                                        }}
                                    >
                                        Send
                                    </Button>
                                </div>
                                <div className="h-6 w-full sm:h-8 md:h-10 flex items-center">
                                    <Text className="ml-1 text-red-500">
                                        {emailError}
                                    </Text>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaveResults
