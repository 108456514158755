import { useDispatch, useSelector } from 'react-redux'
import { Container, Text, Message, LoadingSpinner } from '@/components/UI'
import { AppDispatch, AppState } from '@/types'
import { CourseSearch } from '@/components/Courses/CourseSearch'
import { CourseList } from '@/components/Courses/CourseList'
import { ResultsCardScroll } from '@/components/Results/ResultsCardScroll'
import { AppLayout } from '@/components/UI/AppLayout'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import axios from 'axios'
import { API } from '@/utils'
import {
    resetGrades,
    setGrades,
    setTwentyThreeFiveRule,
} from '@/reducers/grades'
import { setBirthYear, setExperiences } from '@/reducers/experiences'
import {
    setDefaultCourses,
    setAdditionalCourses,
    setIsLoading,
    setError,
} from '@/reducers/courses'
import { setEducationalPrograms } from '@/reducers/educationalPrograms'
import { setReferenceCode, setUserAlias } from '@/reducers/referenceCode'
import { setFavoriteObjects } from '@/reducers/favoriteStudyFields'
import { Button } from '@/components/UI/Button'
import SaveResults from '@/components/UI/SaveResults'
import Experiences from '@/components/Experiences/Experiences'
import { Results } from '@/pages/Results'
import { helperTextFillOutGrades } from '@/helpers/HelperTexts'
import { setBetterThenRegular } from '@/reducers/results'
import { BookCounseling } from '@/components/UI/BookCounseling'

export function Courses() {
    const youngster = useSelector(
        (state: AppState) => state.experiences.youngster
    )
    const betterThenRegular = useSelector(
        (state: AppState) => state.results.betterThenRegular
    )
    const gradePoints = useSelector(
        (state: AppState) => state.results.gradePoints
    )

    const defaultCourses = useSelector(
        (state: AppState) => state.courses.defaultCourses
    )
    const isLoading = useSelector((state: AppState) => state.courses.isLoading)
    const error = useSelector((state: AppState) => state.courses.error)

    const { referenceCode } = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setBetterThenRegular())
    }, [gradePoints])

    useEffect(() => {
        if (referenceCode) {
            dispatch(setReferenceCode(referenceCode))
            const fetchData = async () => {
                try {
                    dispatch(setError(''))
                    dispatch(setIsLoading(true))
                    const response = await axios.get(
                        `${API('calculations')}/${referenceCode}`
                    )
                    dispatch(setUserAlias(response.data.data.userAlias))
                    checkIfIdExistsInLocalStorageAndStore(response.data)
                    updateUI(response.data.data)
                } catch (error: any) {
                    dispatch(setError(error.message))
                } finally {
                    dispatch(setIsLoading(false))
                }
            }
            fetchData()
        } else {
            dispatch(setReferenceCode(''))
        }
    }, [dispatch])

    const updateUI = (data: any) => {
        dispatch(resetGrades())
        dispatch(setGrades(data.grades.grades))
        dispatch(setTwentyThreeFiveRule(data.grades.twentythreeFiveRule))
        dispatch(setBirthYear(data.experiences.birthYear))
        dispatch(setExperiences(data.experiences.experiences))
        dispatch(setEducationalPrograms(data.educationalPrograms.data))
        dispatch(setDefaultCourses(data.courses.defaultCourses))
        dispatch(setAdditionalCourses(data.courses.additionalCourses))
        dispatch(setFavoriteObjects(data.favoriteStudyFields.favoriteObjects))
    }

    const checkIfIdExistsInLocalStorageAndStore = (data: any) => {
        const lagredeKarakterer: string = 'lagredeKarakterer'
        const lsData: any = localStorage.getItem(lagredeKarakterer)
        let parsedLsData: any[] = Array.isArray(lsData) ? lsData : [lsData]

        if (lsData) {
            parsedLsData = JSON.parse(lsData)
        } else {
            parsedLsData = []
        }

        const idExists = parsedLsData.some(
            (grades: any) => grades.id === data.id
        )

        if (parsedLsData.length === 0 || !idExists) {
            parsedLsData.push(data)
            localStorage.setItem(
                lagredeKarakterer,
                JSON.stringify(parsedLsData)
            )
        } else {
            const updatedData = parsedLsData.map((grades: any) =>
                grades.id === data.id ? { ...grades, ...data } : grades
            )
            localStorage.setItem(lagredeKarakterer, JSON.stringify(updatedData))
        }
    }

    if (isLoading) {
        return (
            <AppLayout>
                <LoadingSpinner />
            </AppLayout>
        )
    }

    if (!isLoading && error) {
        return (
            <AppLayout>
                <Container className="animate-reveal">
                    <Text>
                        Dine lagrede karakterer under referansekode{' '}
                        {referenceCode} eksisterer ikke lengre i våre systemer.
                    </Text>
                    <Text>
                        Vi sletter referansekoder som ikke har vært aktive eller
                        benyttet på over 6 mnd.
                    </Text>
                </Container>
            </AppLayout>
        )
    }

    return (
        <AppLayout>
            <Container className="animate-reveal">
                <div className="mt-4 mb-2">
                    <Text type="title" popupContent={helperTextFillOutGrades}>
                        Fyll ut dine karakterer
                    </Text>
                </div>
                <div className="bg-sonans-new-light-gray rounded-2xl p-4 sm:px-10 sm:pb-10 flex-1 mx-auto">
                    <div className="flex flex-col gap-8 max-w-sm md:max-w-5xl mx-auto">
                        <CourseList courses={defaultCourses} />
                        <div className="flex flex-col">
                            <Text className="mb-2 font-bold">Legg til fag</Text>
                            <CourseSearch />
                        </div>
                    </div>
                    <div className={'py-3'}>
                        <Message
                            color="green"
                            content={
                                <Text>
                                    Fellesfagene i utdanningsprogrammet ditt er
                                    lagt til automatisk. Husk å se over samt. å
                                    legge til dine valgfrie programfag.
                                </Text>
                            }
                        />
                    </div>
                </div>
                <Experiences />
            </Container>

            <div className="bg-sonans-new-green py-4 mt-4">
                <Container>
                    <div className="bg-white rounded-xl p-4 sm:p-8 md:p-10 mb-2">
                        <Results />
                        <div className={'py-3'}>
                            <Message
                                color="green"
                                content={
                                    <Text>
                                        Dette er en veiledende utregning, og
                                        kalkulatoren tar ikke høyde for om
                                        fagene du har lagt inn gir generell
                                        studiekompetanse.
                                    </Text>
                                }
                            />
                        </div>
                        <div className="flex flex-col items-center">
                            <Text className="font-bold mb-1">
                                Dine{' '}
                                {youngster && !betterThenRegular
                                    ? 'Karakterpoeng'
                                    : 'konkurransepoeng'}
                            </Text>

                            <ResultsCardScroll className="flex justify-end font-bold" />
                        </div>
                    </div>
                    <div className="bg-white p-4 sm:p-8 md:p-10 rounded-xl">
                        <SaveResults />
                    </div>
                </Container>
            </div>

            <div className="bg-sonans-new-green">
                <Container className="flex justify-between">
                    <Button mode="secondary" icon="left" navigateTo="/start">
                        Tilbake
                    </Button>
                    <Button
                        onClick={() => {
                            navigate('/studiemuligheter')
                        }}
                        icon="right"
                    >
                        Se dine studiemuligheter
                    </Button>
                </Container>
            </div>
            <BookCounseling textType={'calc'} />
        </AppLayout>
    )
}
